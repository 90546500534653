/** @format */

import {DotsVerticalIcon} from '@heroicons/react/solid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import React, {PropsWithChildren, ReactNode} from 'react'

type ActionMenuProps = PropsWithChildren<{
  alignContent?: DropdownMenu.DropdownMenuContentProps['align']
  // By default, when the dropdown menu closes, the trigger will be re-focus
  // (standard behavior for accessibility). When using the action item to
  // open modal, this behavior causes the Dropdown trigger to steal focus
  // from the Modal trap focus mechanism.
  //
  // Pass `false` when using action items to open modal.
  focusTriggerOnClose?: boolean
}>

export function ActionMenu(props: ActionMenuProps) {
  const {alignContent, focusTriggerOnClose = true} = props
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        aria-label="Open Action Menu"
        className="h-5 w-5 shrink-0 rounded-full p-1 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
      >
        <DotsVerticalIcon />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={4}
          align={alignContent}
          className="animate-slide-in-down rounded border bg-white p-1 drop-shadow"
          // not sure why min-w-32 doesn't work
          style={{minWidth: '8rem'}}
          onCloseAutoFocus={
            focusTriggerOnClose ? undefined : e => e.preventDefault()
          }
        >
          {props.children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

interface ActionMenuItemProps {
  color?: ActionMenuItemColor
  disabled?: boolean
  icon?: ReactNode
  label: string
  // Using onClick rather than onSelect because, for some reasons,
  // onSelect doesn't respect stopPropagation.
  // Both handlers fire on selection via either mouse or keyboard.
  onClick?: DropdownMenu.DropdownMenuItemProps['onClick']
}

type ActionMenuItemColor = 'default' | 'danger'

export function ActionMenuItem(props: ActionMenuItemProps) {
  const {color = 'default', disabled, icon, label, onClick} = props
  const enabledClassName = clsx(
    'cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100',
    menuItemColor[color],
  )
  return (
    <DropdownMenu.Item
      className={clsx(
        'flex items-center gap-1',
        'rounded px-2 py-1.5 text-sm font-medium',
        disabled ? 'cursor-default text-disabled' : enabledClassName,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <span className="inline-flex h-4 w-4 shrink-0">{icon}</span>}
      <span className="inline-flex grow">{label}</span>
    </DropdownMenu.Item>
  )
}

const menuItemColor: Record<ActionMenuItemColor, string> = {
  default: 'text-gray',
  danger: 'text-primary',
}
