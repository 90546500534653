/** @format */

import React, {useContext, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import {ModalContext} from '../../components/tailwind/ModalContext'
import LoaderButton from '../../components/tailwind/LoaderButton'
import Input from '../../components/tailwind/Input'
import ajax from '../../ajax'
import {LoadingContext} from '../../contexts/LoadingContext'
import {AccountContext} from '../../contexts/AccountContext'
import {ContextModal} from '../../components/tailwind/ModalContext'

export default function CreateTeamModal(props) {
  const {closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  const {reloadAccount} = useContext(AccountContext)
  const [teamName, setTeamName] = useState('')
  const history = useHistory()

  async function handleCreate(e) {
    e.preventDefault()
    await ajax({
      method: 'POST',
      endpoint: `/api-int/teams`,
      payload: {
        team_name: teamName
      },
      onSuccess: () => {
        reloadAccount()
        closeCurrentModal()
        history.push('/account/team')
      },
      successMsg: `Successfully team created`,
      errorMsg: 'Failed to create team'
    })
    ready()
  }

  return (
    <>
      <ContextModal
        modal="CREATE_TEAM"
        className="narrow"
        title="Create a Team"
      >
        <p className="text-sm text-gray-500">
          Creating a team will start a 14 day trial of the teams feature.
        </p>
        <form onSubmit={handleCreate}>
          <div className="mt-4">
            <label htmlFor="teamName" className="mb-1 mt-4 text-gray-500">
              Team Name
            </label>
            <Input
              autoFocus
              name="teamName"
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-end">
            <LoaderButton
              className="w-full inline-flex px-4 py-2 text-base sm:w-auto sm:ml-3"
              disabled={!teamName}
            >
              Create
            </LoaderButton>
          </div>
        </form>
      </ContextModal>
    </>
  )
}
