/** @format */

import React, {PropsWithChildren} from 'react'

type Props = PropsWithChildren<{
  testid?: string
}>

export function EmptyState(props: Props) {
  const {children, testid} = props
  return (
    <div className="px-4" data-testid={testid}>
      <div className="rounded-lg border-2 border-dashed px-4 py-6">
        {children}
      </div>
    </div>
  )
}
