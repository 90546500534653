/** @format */

import clsx from 'clsx'
import React, {ComponentPropsWithoutRef, ReactNode, useId, useMemo} from 'react'

import {RequiredFieldMarker} from './RequiredFieldMarker'

type TextFieldProps = {
  helpText?: string
  id?: string
  isError?: boolean
  label?: string
  labelVariant?: 'default' | 'subtle' | 'unset'
  suffix?: ReactNode
  showRequiredMarker?: boolean
} & Omit<ComponentPropsWithoutRef<'input'>, 'className' | 'style'>

// TextField is a simple wrapper around label and input. It accepts all props similar to HTML input element.
export function TextField(props: TextFieldProps) {
  const {
    helpText,
    id = useId(),
    isError,
    label,
    labelVariant = 'default',
    suffix,
    showRequiredMarker,
    ...inputProps
  } = props

  const labelClassName = useMemo(() => {
    switch (labelVariant) {
      case 'subtle':
        return 'font-medium text-gray-600'
      case 'unset':
        return undefined
      default:
        return 'font-bold'
    }
  }, [labelVariant])

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          <span>{label}</span>
          {showRequiredMarker && <RequiredFieldMarker />}
        </label>
      )}
      <div
        className={clsx(
          'flex items-center gap-x-2 rounded border-2 border-gray-100 bg-gray-100 px-4 focus-within:ring-1 focus-within:ring-gray-400 focus:outline-none',
          isError && 'focus-within:ring-red-500',
        )}
      >
        <input
          {...inputProps}
          className={clsx(
            'h-10 grow',
            // clear global style on input element from main.css
            'm-0 appearance-none border-none bg-transparent p-0 focus:ring-0',
            isError && 'text-red-500',
          )}
          id={id}
        />
        {suffix && <div className="shrink-0">{suffix}</div>}
      </div>
      {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
    </div>
  )
}
