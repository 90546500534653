/** @format */

import {useMemo} from 'react'

import {useAllFormsContext, useProjectsContext} from '@src/contexts'
import type {Form} from '@src/types/Form'
import type {Project} from '@src/types/Project'

type FormSidebarGroup = {
  groups: Group[]
  noProjectForms: Form[]
}

type Group = {
  forms: Form[]
  key: string
  project: Project
}

export function useFormSidebarGroups(searchTerm: string): FormSidebarGroup {
  const {forms} = useAllFormsContext()
  const {projects} = useProjectsContext()

  const all = useMemo(() => {
    const noProjectFormsKey = '__null__'
    const formsByProjectId = new Map<Project['id'], Set<Form>>()
    for (const f of forms) {
      const pid = f.project_id ?? noProjectFormsKey
      const forms = formsByProjectId.get(pid) ?? new Set<Form>()
      forms.add(f)
      formsByProjectId.set(pid, forms)
    }

    const groups = projects.map(p => {
      const forms = formsByProjectId.get(p.id)
      return {
        forms: forms ? [...forms] : [],
        key: p.id,
        project: p,
      }
    }) satisfies Group[]

    const noProjectForms = formsByProjectId.get(noProjectFormsKey) ?? []
    return {
      groups,
      noProjectForms: [...noProjectForms],
    }
  }, [forms, projects])

  const groups = useMemo(() => {
    if (searchTerm === '') {
      return all.groups
    }

    const re = new RegExp(searchTerm, 'i')
    return all.groups
      .map(g => {
        // If search matches project name, return entire group unchanged
        // This preserves all forms within the project for better context
        if (re.test(g.project.name)) {
          return g
        }

        // For non-matching projects, filter to only show forms
        // whose names match the search term
        const forms = g.forms.filter(f => re.test(f.name))

        // Remove groups with no matching forms
        if (forms.length === 0) {
          return null
        }

        // Return modified group with only matching forms
        return {
          ...g,
          forms,
        }
      })
      .filter((g): g is Group => g != null)
  }, [all.groups, searchTerm])

  const noProjectForms = useMemo(() => {
    if (searchTerm === '') {
      return all.noProjectForms
    }
    const re = new RegExp(searchTerm, 'i')
    return all.noProjectForms.filter(f => re.test(f.name))
  }, [all.noProjectForms, searchTerm])

  return {
    groups,
    noProjectForms,
  }
}
