/** @format */

/**
 * -------------
 * ! Attention !
 * -------------
 * When updating the survey questions, rename the file name with a new version
 * and make sure to update the `survey_version` that is sent with
 * the `onboarding_survey_shown` event to match, since we track the performance
 * for each survey version.
 */

import {useSubmit} from '@formspree/react'
import {RadioGroup, Switch} from '@headlessui/react'
import {useFocusTrap} from '@mantine/hooks'
import clsx from 'clsx'
import merge from 'lodash.merge'
import React, {
  ComponentPropsWithoutRef,
  FormEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import Button from '@src/components/tailwind/Button'
import {CheckboxSwitch} from '@src/components/tailwind/Checkbox'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import * as Modal from '@src/components/tailwind/ModalV2'

import {
  useAccountContext,
  useFormspreeContext,
  useLoadingContext,
} from '@src/contexts'
import {useFetch} from '@src/hooks/useFetch'
import {shuffle} from '@src/lib'
import * as toast from '@src/toast'
import {Account} from '@src/types/Account'
import {formbutton} from '@src/utils'

export function OnboardingSurveyModal() {
  const {account} = useAccountContext()
  const isOpen = account.flags.onboarding_survey_required
  useEffect(() => {
    formbutton()('showButton', !isOpen)
  }, [isOpen])
  return (
    <Modal.Root isOpen={isOpen}>
      <MultiStepForm />
    </Modal.Root>
  )
}

function MultiStepForm() {
  // Each step has its own state and they only "save/restore" their state
  // to parant during the step "submit". It is implemented this way
  // to prevent local changes in a step to cause other steps to re-render.
  const state = useOnboardingSurveyState()
  const [stepIndex, setStepIndex] = useState<0 | 1 | 2 | 3>(0)
  const submitSurvey = useSubmitSurvey()
  return (
    <div className="overflow-hidden">
      <ul
        className="flex transition-transform ease-in-out"
        style={{transform: `translate3d(-${stepIndex * 100}%, 0, 0)`}}
      >
        <li className="shrink-0 grow-0 basis-full">
          <UsageStep
            active={stepIndex === 0}
            initialState={state.usage}
            onContinue={usage => {
              state.setUsage(usage)
              setStepIndex(1)
            }}
          />
        </li>
        <li className="shrink-0 grow-0 basis-full">
          <IndustryStep
            active={stepIndex === 1}
            initialState={state.industry}
            onBack={industry => {
              state.setIndustry(industry)
              setStepIndex(0)
            }}
            onContinue={industry => {
              state.setIndustry(industry)
              setStepIndex(2)
            }}
            title={
              state.usage === 'client'
                ? 'What industries are your clients in?'
                : 'What industries are you in?'
            }
          />
        </li>
        <li className="shrink-0 grow-0 basis-full">
          <ProjectDurationStep
            active={stepIndex === 2}
            initialState={state.projectDuration}
            onBack={projectDuration => {
              state.setProjectDuration(projectDuration)
              setStepIndex(1)
            }}
            onContinue={projectDuration => {
              state.setProjectDuration(projectDuration)
              setStepIndex(3)
            }}
          />
        </li>
        <li className="shrink-0 grow-0 basis-full">
          <DiscoveryStep
            active={stepIndex === 3}
            initialState={state.discovery}
            onBack={discovery => {
              state.setDiscovery(discovery)
              setStepIndex(2)
            }}
            onSubmit={discovery => submitSurvey({...state, discovery})}
          />
        </li>
      </ul>
    </div>
  )
}

function useSubmitSurvey() {
  const {account, updateAccount} = useAccountContext()
  const fetch = useFetch()
  const {ready} = useLoadingContext()

  const {ONBOARDING_SURVEY_FORM_ID} = useFormspreeContext()
  if (!ONBOARDING_SURVEY_FORM_ID) {
    throw new Error('ONBOARDING_SURVEY_FORM_ID is not defined')
  }

  type SubmissionData = {
    account_id: string
    usage: string
    industry: string[]
    industry_other?: string
    project_duration: string
    discovery: string[]
    discovery_other?: string
    discovery_public_content?: string
    discovery_search?: string
  }

  // @ts-ignore: @formspree/core FieldValues type needs to support array of values
  const submitSurvey = useSubmit<SubmissionData>(ONBOARDING_SURVEY_FORM_ID, {
    onError(err) {
      toast.error(
        err
          .getFormErrors()
          .map(e => e.message)
          .join(', '),
      )
      ready()
    },
    async onSuccess() {
      try {
        const patched: Partial<Account> = await fetch(
          '/api-int/account/onboarding-survey-completed',
          {method: 'PUT'},
        )
        // merge mutates object so we need to pass a new object as the target
        updateAccount(prev => merge({}, prev, {account: patched}))
      } catch (err) {
        toast.error('Failed to update survey submission status')
      } finally {
        ready()
      }
    },
  })

  // compact returns a new object where its fields with the value of
  // null or undefined removed.
  function compact<T extends Record<string, unknown>>(obj: T): T {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v)) as T
  }

  return async (state: OnboardingSurveyState): Promise<void> => {
    const {discovery, industry} = state
    submitSurvey(
      compact({
        account_id: account.id,
        usage: state.usage,
        industry: [...industry.selected],
        industry_other: industry.other,
        project_duration: state.projectDuration,
        discovery: [...discovery.selected],
        discovery_other: discovery.extras.get('other'),
        discovery_public_content: discovery.extras.get('public-content'),
        discovery_search: discovery.extras.get('search'),
      }),
    )
  }
}

type OnboardingSurveyState = {
  usage: UsageStepState
  setUsage: (usage: UsageStepState) => void

  industry: IndustryStepState
  setIndustry: (industry: IndustryStepState) => void

  projectDuration: ProjectDurationStepState
  setProjectDuration: (projectDuration: ProjectDurationStepState) => void

  discovery: DiscoveryStepState
  setDiscovery: (discovery: DiscoveryStepState) => void
}

function useOnboardingSurveyState(): OnboardingSurveyState {
  const [usage, setUsage] = useState<OnboardingSurveyState['usage']>('')
  const [industry, setIndustry] = useState<OnboardingSurveyState['industry']>({
    other: '',
    selected: new Set(),
  })
  const [projectDuration, setProjectDuration] =
    useState<OnboardingSurveyState['projectDuration']>('')
  const [discovery, setDiscovery] = useState<
    OnboardingSurveyState['discovery']
  >({
    extras: new Map(),
    selected: new Set(),
  })

  return {
    usage,
    setUsage,

    industry,
    setIndustry,

    projectDuration,
    setProjectDuration,

    discovery,
    setDiscovery,
  }
}

const stepProps = {
  className:
    'grid gap-y-10 h-full max-h-screen sm:max-h-[80vh] py-8 px-6 md:pt-12 md:px-8',
  style: {
    gridTemplateRows: 'auto 1fr auto',
  },
} as const

type UsageStepProps = {
  active: boolean
  initialState: UsageStepState
  onContinue: (state: UsageStepState) => void
}

type UsageStepState = string

const usageOptions = shuffle([
  {
    key: 'personal-project',
    label: 'I want to try out formspree on personal projects',
  },
  {
    key: 'own-company',
    label: 'I want to create forms for my company',
  },
  {
    key: 'client',
    label: 'I want to create forms for client projects',
  },
])

function UsageStep(props: UsageStepProps) {
  const {active, initialState, onContinue} = props
  const {account} = useAccountContext()
  const focusTrap = useFocusTrap(active)
  const [state, setState] = useState(initialState)
  const canContinue = !!state

  function handleSubmit(event: FormEvent): void {
    event.preventDefault()
    if (canContinue) {
      onContinue(state)
    }
  }

  return (
    <form {...stepProps} onSubmit={handleSubmit} ref={focusTrap}>
      <header className="text-center">
        <h2 className="leading-0 mb-2 text-xl font-semibold md:text-2xl">
          Welcome, {account.first_name}. Tell us about&nbsp;yourself.
        </h2>
        <p className="text-gray-700">
          This will help our team build a better experience for you.
        </p>
      </header>

      <RadioGroup
        aria-required="true"
        className="flex flex-col gap-y-4 overflow-y-auto"
        value={state}
        onChange={setState}
      >
        <RadioGroup.Label className="font-medium text-gray-600">
          Why did you sign up?
        </RadioGroup.Label>
        {usageOptions.map(({key, label}) => (
          <RadioOption key={key} label={label} value={key} />
        ))}
      </RadioGroup>

      <footer className="grid grid-cols-2 gap-x-6">
        <Button primary disabled={!canContinue} className="col-start-2">
          Continue
        </Button>
      </footer>
    </form>
  )
}

type IndustryStepProps = {
  active: boolean
  initialState: IndustryStepState
  onBack: (state: IndustryStepState) => void
  onContinue: (state: IndustryStepState) => void
  title: string
}

type IndustryStepState = {
  other: string
  selected: Set<string>
}

const industryOptions: string[] = shuffle([
  'Ecommerce',
  'Professional services',
  'Hospitality',
  'Storefront / Retail',
  'Fitness / Personal Care',
  'Media / Publication',
  'Technology',
  'Healthcare',
  'Education',
])

industryOptions.push('Other')

function IndustryStep(props: IndustryStepProps) {
  const {active, initialState, onBack, onContinue, title} = props
  const focusTrap = useFocusTrap(active)

  const [other, setOther] = useState(initialState.other)
  const [selected, setSelected] = useState(initialState.selected)
  const state = {other, selected}

  const canContinue =
    selected.size > 0 && (!selected.has('Other') || !!other.trim())

  function handleSubmit(event: FormEvent): void {
    event.preventDefault()
    if (canContinue) {
      onContinue(state)
    }
  }

  return (
    <form {...stepProps} onSubmit={handleSubmit} ref={focusTrap}>
      <h2 className="leading-0 text-center text-xl font-semibold md:text-2xl">
        {title}
      </h2>

      <fieldset className="flex flex-col gap-y-4 overflow-y-auto">
        {industryOptions.map(v => {
          const checked = selected.has(v)

          function handleCheckedChange(checked: boolean): void {
            checked ? selected.add(v) : selected.delete(v)
            setSelected(new Set(selected))
          }

          if (v === 'Other') {
            return (
              <CheckboxBlock
                key={v}
                label={v}
                checked={checked}
                onChange={handleCheckedChange}
              >
                <ExtraInput
                  onChange={event => setOther(event.target.value)}
                  placeholder="Other industry"
                  value={other}
                />
              </CheckboxBlock>
            )
          }

          return (
            <CheckboxBlock
              key={v}
              label={v}
              checked={checked}
              onChange={handleCheckedChange}
            />
          )
        })}
      </fieldset>

      <footer className="grid grid-cols-2 gap-x-6">
        <Button type="button" onClick={() => onBack(state)}>
          Back
        </Button>
        <Button primary disabled={!canContinue}>
          Continue
        </Button>
      </footer>
    </form>
  )
}

type ProjectDurationStepProps = {
  active: boolean
  initialState: ProjectDurationStepState
  onBack: (state: ProjectDurationStepState) => void
  onContinue: (state: ProjectDurationStepState) => void
}

type ProjectDurationStepState = string

const projectDurationOptions = shuffle([
  {
    key: 'gt-1yr',
    label: 'More than a year',
  },
  {
    key: '1mo-to-1yr',
    label: 'More than a month but less than a year',
  },
  {
    key: 'lt-1mo',
    label: 'A month or less',
  },
])

function ProjectDurationStep(props: ProjectDurationStepProps) {
  const {active, initialState, onBack, onContinue} = props
  const focusTrap = useFocusTrap(active)
  const [state, setState] = useState(initialState)
  const canContinue = !!state

  function handleSubmit(event: FormEvent): void {
    event.preventDefault()
    if (canContinue) {
      onContinue(state)
    }
  }

  return (
    <form {...stepProps} onSubmit={handleSubmit} ref={focusTrap}>
      <h2 className="leading-0 text-center text-xl font-semibold md:text-2xl">
        What&apos;s the duration of your&nbsp;project?
      </h2>

      <RadioGroup
        aria-required="true"
        className="flex flex-col gap-y-4 overflow-y-auto"
        value={state}
        onChange={setState}
      >
        <RadioGroup.Label className="font-medium text-gray-600">
          Select one:
        </RadioGroup.Label>
        {projectDurationOptions.map(({key, label}) => (
          <RadioOption key={key} label={label} value={key} />
        ))}
      </RadioGroup>

      <footer className="grid grid-cols-2 gap-x-6">
        <Button type="button" onClick={() => onBack(state)}>
          Back
        </Button>
        <Button primary disabled={!canContinue}>
          Continue
        </Button>
      </footer>
    </form>
  )
}

type DiscoveryStepProps = {
  active: boolean
  initialState: DiscoveryStepState
  onBack: (state: DiscoveryStepState) => void
  onSubmit: (state: DiscoveryStepState) => void
}

type DiscoveryStepState = {
  extras: Map<DiscoveryKey, string>
  selected: Set<DiscoveryKey>
}

type DiscoveryKey =
  | 'developer'
  | 'formspree-powered-form'
  | 'other'
  | 'public-content'
  | 'search'
  | 'used-in-previous-project'

const discoveryOptions: {
  extraInput?: {
    label: string
  }
  key: DiscoveryKey
  label: string
}[] = shuffle([
  {
    label: 'Our developer recommended it',
    key: 'developer',
  },
  {
    label: 'I filled out a form that was powered by Formspree',
    key: 'formspree-powered-form',
  },
  {
    extraInput: {
      label: 'Which one?',
    },
    label: 'I saw a blog post, tutorial or video mentioning it',
    key: 'public-content',
  },
  {
    extraInput: {
      label: 'What were you searching for?',
    },
    label: 'I found Formspree while searching for form solutions',
    key: 'search',
  },
  {
    label: 'I’ve used Formspree on a project in the past',
    key: 'used-in-previous-project',
  },
])

discoveryOptions.push({
  extraInput: {
    label: 'Other source',
  },
  label: 'Other',
  key: 'other',
})

function DiscoveryStep(props: DiscoveryStepProps) {
  const {active, initialState, onBack, onSubmit} = props
  const focusTrap = useFocusTrap(active)

  const [selected, setSelected] = useState(initialState.selected)
  const [extras, setExtras] = useState<Map<DiscoveryKey, string>>(new Map())
  const state = {extras, selected}

  const canSubmit =
    selected.size > 0 &&
    discoveryOptions.every(({extraInput, key}) =>
      // extra input is required if the option is selected
      extraInput && selected.has(key) ? !!extras.get(key)?.trim() : true,
    )

  function handleSubmit(event: FormEvent): void {
    event.preventDefault()
    if (canSubmit) {
      onSubmit(state)
    }
  }

  return (
    <form {...stepProps} onSubmit={handleSubmit} ref={focusTrap}>
      <h2 className="leading-0 text-center text-xl font-semibold md:text-2xl">
        How did you hear about&nbsp;Formspree?
      </h2>

      <fieldset className="flex flex-col gap-y-4 overflow-y-auto">
        {discoveryOptions.map(({extraInput, key, label}) => {
          const checked = selected.has(key)

          function handleCheckedChange(checked: boolean): void {
            checked ? selected.add(key) : selected.delete(key)
            setSelected(new Set(selected))
          }

          return (
            <CheckboxBlock
              key={key}
              label={label}
              checked={checked}
              onChange={handleCheckedChange}
            >
              {extraInput && (
                <ExtraInput
                  aria-required
                  onChange={event =>
                    setExtras(new Map(extras.set(key, event.target.value)))
                  }
                  placeholder={extraInput.label}
                  value={extras.get(key) ?? ''}
                />
              )}
            </CheckboxBlock>
          )
        })}
      </fieldset>

      <footer className="grid grid-cols-2 gap-x-6">
        <Button type="button" onClick={() => onBack(state)}>
          Back
        </Button>
        <LoaderButton disabled={!canSubmit}>Submit</LoaderButton>
      </footer>
    </form>
  )
}

type CheckboxBlockProps = {
  children?: ReactElement<typeof ExtraInput>
  label: string
} & ComponentPropsWithoutRef<typeof CheckboxSwitch>

function CheckboxBlock(props: CheckboxBlockProps) {
  const {checked, children, id = useId(), label, ...switchProps} = props
  return (
    <Switch.Group>
      <div
        className={clsx(
          'rounded-lg px-3 md:px-4',
          checked ? 'bg-red-100' : 'bg-gray-100',
        )}
      >
        <div className="flex items-center gap-2 py-2.5 md:py-3.5">
          <CheckboxSwitch {...switchProps} checked={checked} id={id} />
          <label className="grow cursor-pointer select-none" htmlFor={id}>
            {label}
          </label>
        </div>
        {checked && children && <div className="pb-4">{children}</div>}
      </div>
    </Switch.Group>
  )
}

type ExtraInputProps = Omit<ComponentPropsWithoutRef<'input'>, 'className'>

function ExtraInput(props: ExtraInputProps) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const el = ref.current
    el?.focus({preventScroll: true})
    el?.scrollIntoView({behavior: 'smooth'})
  }, [])

  return <input {...props} className="m-0 px-2 py-1 text-sm" ref={ref} />
}

type RadioOptionProps = {
  id?: string
  label: ReactNode
} & ComponentPropsWithoutRef<typeof RadioGroup.Option>

function RadioOption(props: RadioOptionProps) {
  const {id = useId(), label, ...optionProps} = props
  return (
    <RadioGroup.Option {...optionProps} className="focus:outline-none">
      {({active, checked}) => {
        return (
          <div className="flex items-center gap-x-2 focus:outline-none">
            <button
              aria-checked={checked ? 'true' : 'false'}
              className={clsx(
                'flex h-4 w-4 shrink-0 items-center justify-center rounded-full border focus:outline-none',
                active && 'border-primary',
                checked ? 'border-primary bg-primary' : 'border-gray-300',
              )}
              id={id}
              role="radio"
              type="button"
            >
              {checked && (
                <i
                  aria-hidden="true"
                  className="block h-1.5 w-1.5 rounded-full bg-white shadow-sm"
                />
              )}
            </button>
            <label htmlFor={id}>{label}</label>
          </div>
        )
      }}
    </RadioGroup.Option>
  )
}
