/** @format */

import React, {FC, PropsWithChildren} from 'react'
import {NavLink, NavLinkProps} from 'react-router-dom'

const Item = ({
  selected = false,
  children,
}: PropsWithChildren<{selected?: boolean}>) => {
  return (
    <div
      className={`pb-2 ${
        selected ? 'border-b-2 border-primary text-gray-900' : ''
      }`}
    >
      {children}
    </div>
  )
}

const Nav: FC<PropsWithChildren> & {Item: typeof Item} = ({children}) => {
  return (
    <div className="flex flex-wrap gap-4 text-sm font-medium text-gray-500">
      {children}
    </div>
  )
}

export function NavLinkItem(props: Pick<NavLinkProps, 'children' | 'to'>) {
  const {children, to} = props
  return (
    <NavLink
      to={to}
      className="pb-2 hover:text-gray-900"
      activeClassName="border-b-2 border-primary text-gray-900"
    >
      {children}
    </NavLink>
  )
}

Nav.Item = Item
export default Nav
