/** @format */

import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import ButtonChoices from '@src/components/tailwind/ButtonChoices'
import {
  CloseModalButton,
  ContextModal,
} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {AnalyticsEvent, sendAnalytics} from '@src/analytics'
import {useAccountContext, useProjectsContext} from '@src/contexts'
import {NewProject, Project, ProjectKind} from '@src/types/Project'
import {getLimit} from '@src/utils'

const PROJECT_OPTIONS = [
  {
    value: 'dashboard',
    label: 'Dashboard Project',
    description: 'Create and manage forms in the Formspree dashboard.',
  },
  {
    value: 'cli',
    label: 'Command Line Project',
    description:
      'Add forms in your formspree.json config file. Use the Formspree CLI to deploy and update forms.',
  },
]

type Props = {
  modal: string
}

export function CreateProjectModal(props: Props) {
  const {modal} = props
  const {account} = useAccountContext()
  const history = useHistory()
  const userProjectsLimit = getLimit(account, 'projects')
  const {projects = [], updateProjectState} = useProjectsContext()

  const [newProject, setNewProject] = useState<NewProject>({
    name: 'A New Project',
    kind: 'dashboard',
  })

  const handleCreateProject = async () => {
    let payload = {...newProject}
    await ajax({
      method: 'POST',
      endpoint: `/api-int/projects`,
      errorMsg: 'Failed to create project',
      successMsg: 'Project created successfully.',
      onSuccess: (createdProject: Project) => {
        updateProjectState(createdProject.id, createdProject)
        history.push(`/projects/${createdProject.id}/settings`)
      },
      payload,
    })
  }

  return (
    <ContextModal modal={modal} title="Create Project">
      {projects.length < userProjectsLimit ? (
        <form data-testid="CreateProjectModal">
          <div className="inline-block w-full">
            <label>Project Name:</label>
            <input
              className="mt-2"
              type="text"
              value={newProject.name}
              onChange={e =>
                setNewProject({
                  ...newProject,
                  name: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="mt-5 inline-block w-full">
            <ButtonChoices
              label="Project Type"
              value={newProject.kind}
              choices={PROJECT_OPTIONS}
              onChange={(kind: ProjectKind) =>
                setNewProject({...newProject, kind})
              }
            />
            <p className="mt-4">
              To learn more about project types,{' '}
              <a
                href="https://help.formspree.io/hc/en-us/articles/360053239754"
                className="primaryLink"
              >
                click here
              </a>
              .
            </p>
          </div>
          <div className="mt-5 inline-block w-full">
            <CloseModalButton
              className="buttonPrimary float-right"
              onClick={handleCreateProject}
            >
              Create Project
            </CloseModalButton>
          </div>
        </form>
      ) : (
        <UpgradeMessage />
      )}
    </ContextModal>
  )
}

function UpgradeMessage() {
  function handleClick() {
    sendAnalytics(AnalyticsEvent.upgrade_clicked, {
      feature: 'projects',
    })
  }
  return (
    <p>
      <span>
        Oops, you have reached your project limit. Please considering upgrading
        on the&nbsp;
      </span>
      <Link className="primaryLink" onClick={handleClick} to="/plans">
        plans
      </Link>
      <span>&nbsp;page.</span>
    </p>
  )
}
