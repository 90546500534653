/** @format */

import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {FullPageLoading} from '@src/components/tailwind/Loading'

const PlansPage = React.lazy(() => import('./plans/PlansPage'))
const LoginPage = React.lazy(() =>
  import('./login/LoginPage').then(module => ({default: module.LoginPage})),
)
const AcceptTeamInvite = React.lazy(() =>
  import('./accounts/teams/TeamInvitations/AcceptTeamInvite').then(module => ({
    default: module.AcceptTeamInvite,
  })),
)
const Register = React.lazy(() => import('@src/register/Register'))

export default function SeparatePageRouter() {
  return (
    <React.Suspense fallback={<FullPageLoading />}>
      <Switch>
        <Route exact path="/invite/confirm" component={AcceptTeamInvite} />
        <Route exact path="/plans" component={PlansPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/register" component={Register} />
      </Switch>
    </React.Suspense>
  )
}
