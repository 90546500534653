/** @format */

import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {Link, useHistory} from 'react-router-dom'

import Button from '@src/components/tailwind/Button'
import {
  defaultToOption,
  FormspreeSelect,
  Option,
} from '@src/components/tailwind/FormspreeSelectV2'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import {
  ContextModal,
  useModalContext,
} from '@src/components/tailwind/ModalContext'
import * as Modal from '@src/components/tailwind/ModalV2'
import {TextField} from '@src/components/tailwind/TextField'

import ajax from '@src/ajax'
import {
  useAccountContext,
  useAllFormsContext,
  useProjectsContext,
} from '@src/contexts'
import {Project} from '@src/types/Project'

type Props = {
  currentProject: Project
  modal: string
}

export function CreateFormModal(props: Props) {
  const {currentProject, modal} = props

  const {emails} = useAccountContext()
  const emailSelectState = useEmailSelectState()
  const {closeCurrentModal} = useModalContext()
  const [name, setName] = useState('A New Form')
  const projectSelectState = useProjectSelectState(currentProject)

  const {reloadAllForms} = useAllFormsContext()
  const history = useHistory()

  async function handleCreateForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const payload = {
      email: emailSelectState.value?.value ?? null,
      name,
      project: projectSelectState.value?.id,
    }
    if (!parseInt(payload.project as string, 10)) {
      delete payload.project
    }
    await ajax({
      method: 'POST',
      endpoint: '/api-int/forms',
      errorMsg: 'Failed to create form',
      successMsg: 'Form created successfully.',
      onSuccess: async ({hashid}: {hashid: string}) => {
        await reloadAllForms()
        history.push(`/forms/${hashid}/integration`)
        closeCurrentModal()
      },
      payload,
    })
  }

  if (emails.verified.length === 0) {
    return <VerifyEmailAddressModal modal={modal} />
  }

  return (
    <ContextModal modal={modal} title="Create Form">
      <form className="flex flex-col gap-y-4" onSubmit={handleCreateForm}>
        <TextField
          label="Form Name:"
          labelVariant="unset"
          onChange={event => setName(event.target.value)}
          required
          value={name}
        />
        {projectSelectState.options.length > 0 && (
          <FormspreeSelect
            {...projectSelectState}
            // Don't add isClearable props, we don't allow creating forms
            // without a project.
            //
            // Unfortunately, due to react-select limitations, we cannot add
            // unit tests for this behavior.
            label="Project:"
            testid="CreateFormModal:ProjectSelect"
          />
        )}
        <FormspreeSelect
          {...emailSelectState}
          label="Send emails to:"
          required
        />
        <p>
          <span>
            To send to a new email address, please first add it to Linked Emails
            on the{' '}
          </span>
          <Link className="primaryLink" to="/account">
            account
          </Link>
          <span> page.</span>
        </p>
        <LoaderButton className="ms-auto">Create Form</LoaderButton>
      </form>
    </ContextModal>
  )
}

type ProjectSelectState = {
  isClearable: false
  getOptionLabel: (option: Project) => string
  getOptionValue: (option: Project) => string
  onChange: (selected: Project | null) => void
  options: Project[]
  value: Project | null
}

function useProjectSelectState(currentProject: Project): ProjectSelectState {
  const {dashboardProjects} = useProjectsContext()
  const [value, setValue] = useState<Project>(currentProject)
  const onChange = useCallback((selected: Project | null) => {
    // This should never happen, since we set isClearable to false.
    // However, since react-select types are not robust enough
    // to allow the type annotation to guarantee safety, we have to add
    // a runtime check here.
    if (selected == null) {
      throw new Error('Selection Error: null selection is not allowed.')
    }
    setValue(selected)
  }, [])

  useEffect(() => {
    setValue(currentProject)
  }, [currentProject])

  return {
    getOptionLabel: useCallback(p => p.name, []),
    getOptionValue: useCallback(p => p.id, []),
    isClearable: false,
    onChange,
    options: dashboardProjects,
    value,
  }
}

type EmailSelectState = {
  onChange: (selected: Option<string> | null) => void
  options: Option<string>[]
  value: Option<string> | null
}

function useEmailSelectState(): EmailSelectState {
  const {emails} = useAccountContext()
  const {verified} = emails
  // unfortunately, we can't just use string[] as options with React Select
  // due to https://github.com/JedWatson/react-select/issues/5032
  // so we just have to dance around like this until the issue is fixed.
  const options = useMemo(() => verified.map(defaultToOption), [verified])
  const [value, setValue] = useState<Option<string> | null>(options[0])
  return {
    onChange: setValue,
    options,
    value,
  }
}

type VerifyEmailAddressModalProps = {
  modal: string
}

function VerifyEmailAddressModal(props: VerifyEmailAddressModalProps) {
  const {modal} = props
  const {closeCurrentModal, isOpen} = useModalContext()
  return (
    <Modal.Root isOpen={isOpen(modal)} onClose={closeCurrentModal}>
      <Modal.DefaultLayout>
        <Modal.Header title="Verify Email Address" />
        <Modal.Content>
          <p className="mb-2">
            Your email address has not been verified. Please check your inbox
            and click the link in the verification email.
          </p>
          <p>
            <span>You can add or manage your Linked Email Address on the </span>
            <Link className="primaryLink" to="/account">
              account
            </Link>
            <span> page.</span>
          </p>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex flex-row-reverse">
            <Button onClick={closeCurrentModal}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal.DefaultLayout>
    </Modal.Root>
  )
}
