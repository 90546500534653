/** @format */

import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import React, {StrictMode, useEffect} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter, withRouter} from 'react-router-dom'

import {FormspreeContextProvider, ProductsApiProvider} from '@src/contexts'
import {AccountContextProvider} from '@src/contexts/AccountContext'
import {LoadingContextProvider} from '@src/contexts/LoadingContext'

import Dashboard from './dashboard/Dashboard'
import IFrameRouter from './dashboard/IFrameRouter'
import {logPageTransition, PageTransition} from './PageTransition'
import SeparatePageRouter from './SeparatePageRouter'

/** @format */

const amplitude = window.amplitude
const url = require('url')

Sentry.init({
  dsn: window.formspree.SENTRY_DSN_REACT,
  integrations: [
    new Integrations.BrowserTracing(),
    Sentry.replayIntegration(),
  ],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  tracesSampleRate: window.formspree.SENTRY_SAMPLE_RATE_REACT,
  replaysOnErrorSampleRate: window.formspree.SENTRY_REPLAY_ERROR_RATE_REACT,
  replaysSessionSampleRate: window.formspree.SENTRY_REPLAY_SAMPLE_RATE_REACT,
})

const App = withRouter(({location}) => {
  useEffect(() => {
    const ampInst = amplitude && amplitude.getInstance()
    if (ampInst) {
      ampInst.init(window.formspree.AMPLITUDE_KEY, null, {
        // Configuration options. Do not change without consulting legal.
        includeReferrer: true,
        includeUtm: true,
        saveParamsReferrerOncePerSession: false,
        trackingOptions: {
          carrier: true,
          city: false,
          country: true,
          device_model: true,
          dma: false,
          ip_address: false,
          language: true,
          os_name: true,
          os_version: true,
          platform: true,
          region: false,
          version_name: true,
        },
      })
      logPageTransition(url.parse(window.location.href).pathname)
    }
  }, [])

  const isUserOptionalPage = location.pathname.match(
    /^\/(plans|login|register)\/?/,
  )
  const isIFrame = location.pathname.match(/^\/iframe\/.+/)
  const isSeparatePage = location.pathname.match(
    /^\/(plans|login|invite\/confirm|register)\/?/,
  )

  return (
    <FormspreeContextProvider>
      <ProductsApiProvider>
        <LoadingContextProvider>
          <AccountContextProvider userOptional={isUserOptionalPage}>
            {isIFrame ? (
              <IFrameRouter />
            ) : (
              <PageTransition>
                {isSeparatePage ? <SeparatePageRouter /> : <Dashboard />}
              </PageTransition>
            )}
          </AccountContextProvider>
        </LoadingContextProvider>
      </ProductsApiProvider>
    </FormspreeContextProvider>
  )
})

createRoot(document.getElementById('body')).render(
  <StrictMode>
    <BrowserRouter>
      {window.formspree && window.formspree.SENTRY_DSN_REACT ? (
        <Sentry.ErrorBoundary fallback={'An error has occurred'} showDialog>
          <App />
        </Sentry.ErrorBoundary>
      ) : (
        <App />
      )}
    </BrowserRouter>
  </StrictMode>,
)
