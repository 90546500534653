/** @format */

import {PlusCircleIcon} from '@heroicons/react/solid'
import clsx from 'clsx'
import React, {MouseEvent, useCallback, useEffect, useState} from 'react'
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import {ActionMenu} from '@src/components/tailwind/ActionMenu'
import {useModalContext} from '@src/components/tailwind/ModalContext'

import type {Form} from '@src/types/Form'
import type {Project} from '@src/types/Project'
import {getDefaultFormPageUrl, getFormName} from '@src/utils'

import {CreateFormModal} from './CreateFormModal'
import {EmptyState} from './EmptyState'
import {Menu, MenuItem} from './Menu'

type FormsByProjectGroupProps = {
  forms: Form[]
  project: Project
}

export function FormsByProjectGroup(props: FormsByProjectGroupProps) {
  const {forms, project} = props
  const expander = useExpanderState(project)
  const modal = `CREATE_FORM_MODAL:${project.id}`
  const {openModal} = useModalContext()
  return (
    <section>
      <CreateFormModal currentProject={project} modal={modal} />
      <GroupHeader
        isExpanded={expander.value}
        onExpandButtonClick={expander.toggle}
        onNewFormSelect={() => openModal(modal)}
        project={project}
      />
      {expander.value &&
        (forms.length > 0 ? (
          <main className="flex flex-col gap-y-1 px-4 pt-1">
            {forms.map(f => (
              <FormLink form={f} key={f.hashid} />
            ))}
          </main>
        ) : (
          <div className="pt-4">
            {project.kind === 'dashboard' ? (
              <EmptyDashboardProject project={project} />
            ) : (
              <EmptyCliProject project={project} />
            )}
          </div>
        ))}
    </section>
  )
}

type GroupHeaderProps = {
  isExpanded: boolean
  onExpandButtonClick: () => void
  onNewFormSelect: () => void
  project: Project
}

function GroupHeader(props: GroupHeaderProps) {
  const {isExpanded, onExpandButtonClick, onNewFormSelect, project} = props
  const history = useHistory()
  const location = useLocation()
  const isActive = !!useRouteMatch(`/projects/${project.id}`)
  return (
    <header
      className={clsx(
        'flex items-stretch gap-x-1 py-2 pe-4 ps-3',
        isActive ? 'bg-gray-50' : 'hover:bg-gray-50',
      )}
      data-testid="FormSidebar:FormsByProjectGroup:GroupHeader"
    >
      <button
        className="shrink-0 px-1 py-1.5"
        onClick={onExpandButtonClick}
        type="button"
      >
        <CaretIcon
          className={clsx(
            'h-2 w-2 text-gray-500 transition-transform',
            isExpanded ? 'rotate-0' : '-rotate-90',
          )}
        />
      </button>
      <Link
        className="flex grow items-center gap-x-1 text-xs font-medium uppercase text-gray-500"
        to={`/projects/${project.id}/overview`}
      >
        {project.name}
      </Link>
      <div className="shrink-0 translate-x-1.5">
        <ActionMenu alignContent="end">
          <Menu>
            {/* Only allow adding form to dashboard projects */}
            {project.kind === 'dashboard' && (
              <MenuItem onSelect={onNewFormSelect}>New Form</MenuItem>
            )}
            <MenuItem
              onSelect={() => {
                const to = `/projects/${project.id}/settings`
                // Don't push to history, if we're already on the page
                if (location.pathname !== to) {
                  history.push(to)
                }
              }}
            >
              Project settings
            </MenuItem>
          </Menu>
        </ActionMenu>
      </div>
    </header>
  )
}

type FormLinkProps = {
  form: Form
}

function FormLink(props: FormLinkProps) {
  const {form} = props
  const isActive = !!useRouteMatch(`/forms/${form.hashid}`)
  return (
    <NavLink
      className={clsx(
        'rounded px-4 py-2 text-sm font-semibold',
        isActive ? 'bg-gray-50' : 'hover:bg-gray-50',
      )}
      to={getDefaultFormPageUrl(form)}
    >
      {getFormName(form)}
    </NavLink>
  )
}

type ExpanderState = {
  toggle: () => void
  value: boolean
}

function useExpanderState(project: Project): ExpanderState {
  // Match tailwind/Expander.jsx for legacy reasons.
  const key = `__fs_sidebar_expander__:project:${project.id}`
  const [value, setValue] = useState(() => {
    const {localStorage} = window

    // Migrate from the legacy system (tailwind/Expander.jsx), because
    // it saves the "collapsed" state for "expander", which is confusing.
    const legacyKey = `__fs_expander_collapsed_projects__:${project.id}`
    const legacy = localStorage.getItem(legacyKey)
    if (legacy != null) {
      const collapsed = legacy === 'true'
      // Revert the value for the new key.
      localStorage.setItem(key, collapsed ? 'false' : 'true')
      localStorage.removeItem(legacyKey)
    }

    const saved = localStorage.getItem(key)
    // If nothing is saved, it is expanded by default.
    return saved ? saved === 'true' : true
  })

  useEffect(() => {
    localStorage.setItem(key, value ? 'true' : 'false')
  }, [key, value])

  return {
    toggle: useCallback(() => setValue(v => !v), []),
    value,
  }
}

type EmptyProject = {
  project: Project
}

function EmptyCliProject(props: EmptyProject) {
  const {project} = props
  const onInputClick = useCallback(
    (event: MouseEvent<HTMLInputElement>): void => event.currentTarget.select(),
    [],
  )
  return (
    <EmptyState testid="FormSidebar:EmptyCliProject">
      <dl className="flex flex-col gap-y-2 text-xs font-medium text-gray-500">
        <div className="flex items-center justify-between">
          <dt>Project ID:</dt>
          <dd>
            <input
              className="w-32 rounded border border-solid border-gray-300 px-1 py-0 font-code focus:ring-transparent"
              onClick={onInputClick}
              readOnly
              value={project.id}
            />
          </dd>
        </div>
        <div className="flex items-center justify-between">
          <dt>Deploy Key:</dt>
          <dd>
            <input
              className="w-32 rounded border border-solid border-gray-300 px-1 py-0 font-code focus:ring-transparent"
              onClick={onInputClick}
              readOnly
              value={project.apikey}
            />
          </dd>
        </div>
      </dl>
      <p className="mt-2 text-xs font-medium text-gray-500">
        <span>Add forms with the Formspree CLI. </span>
        <a
          className="primaryLink"
          href="https://help.formspree.io/hc/en-us/articles/360053819114"
        >
          Read more
        </a>
        <span>.</span>
      </p>
    </EmptyState>
  )
}

function EmptyDashboardProject(props: EmptyProject) {
  const {project} = props
  const {openModal} = useModalContext()
  const modal = `CreateFormModal:EmptyDashboardProject:${project.id}`
  return (
    <EmptyState testid="FormSidebar:EmptyDashboardProject">
      <CreateFormModal currentProject={project} modal={modal} />
      <div className="flex items-center justify-center gap-x-1 text-sm font-normal text-gray-600">
        <span>Click</span>
        <button
          aria-label={`Add form button for project: ${project.name}`}
          onClick={() => openModal(modal)}
          type="button"
        >
          <PlusCircleIcon className="h-7 w-7 shrink-0 text-primary" />
        </button>
        <span>to create a form.</span>
      </div>
    </EmptyState>
  )
}

type NoProjectFormsGroupProps = {
  forms: Form[]
}

export function NoProjectFormsGroup(props: NoProjectFormsGroupProps) {
  const {forms} = props
  return (
    <section>
      <header
        className="flex items-center gap-x-1 py-2 pe-4 ps-3"
        data-testid="FormSidebar:NoProjectFormsGroup:GroupHeader"
      >
        {/* Add an invisible caret button to keep the layout the same as the other groups */}
        <button
          className="pointer-events-none invisible shrink-0 px-1 py-1.5"
          disabled
          type="button"
        >
          <CaretIcon className="h-2 w-2" />
        </button>
        <p className="ms-flex grow select-none items-center gap-x-1 text-xs font-medium uppercase text-gray-500">
          No Project
        </p>
      </header>
      <main className="flex flex-col gap-y-1 px-4">
        {forms.map(f => (
          <FormLink form={f} key={f.hashid} />
        ))}
      </main>
    </section>
  )
}

type CaretIconProps = {
  className: string
}

function CaretIcon(props: CaretIconProps) {
  const {className} = props
  return (
    <svg
      aria-hidden
      className={className}
      viewBox="0 0 7 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M7 0.75H0L3.5 7.25L7 0.75Z" />
    </svg>
  )
}
