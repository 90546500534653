/** @format */

import {SearchIcon} from '@heroicons/react/solid'
import React, {useRef} from 'react'

type FormSearchProps = {
  onChange: (searchTerm: string) => void
}

export function FormSearch(props: FormSearchProps) {
  const {onChange} = props
  const input = useRef<HTMLInputElement>(null)

  return (
    <form className="flex items-center rounded bg-gray-50 px-2 py-1 outline-none ring-1 ring-gray-300 focus-within:ring-gray-500">
      <SearchIcon
        aria-hidden
        className="h-6 w-6 flex-none border-none bg-transparent text-gray-500"
      />
      <input
        className="m-0 border-0 bg-transparent px-2 py-1 text-sm focus:ring-0"
        onChange={event => onChange(event.target.value)}
        placeholder="Search forms and projects…"
        ref={input}
      />
    </form>
  )
}
