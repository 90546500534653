/** @format */

import {ChevronRightIcon, XIcon} from '@heroicons/react/outline'
import * as Dialog from '@radix-ui/react-dialog'
import * as Tooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import React, {
  createContext,
  useContext,
  useRef,
  type PropsWithChildren,
} from 'react'

import {FormSidebar} from '@src/dashboard/FormSidebar'

type DashboardLayoutProps = PropsWithChildren<{
  background?: string
}>

function DashboardLayout(props: DashboardLayoutProps) {
  const {background = 'bg-gray-50', children} = props
  return (
    <Tooltip.Provider delayDuration={360}>
      <div
        className={clsx(
          'flex h-[calc(100vh-var(--navbar-height))] border-t border-gray-100',
          background,
        )}
      >
        {children}
      </div>
    </Tooltip.Provider>
  )
}

type SidebarProps = PropsWithChildren<{
  background?: string
}>

function Sidebar(props: SidebarProps) {
  const {background = 'bg-white', children} = props
  return (
    <div
      className={clsx(
        'hidden shrink-0 basis-[18.75rem] border-r border-gray-100 md:flex md:flex-col',
        background,
      )}
    >
      <div className="overflow-y-auto">{children}</div>
    </div>
  )
}

const MainElementContext = createContext<HTMLElement | null>(null)

function Main(props: PropsWithChildren) {
  const {children} = props
  const ref = useRef<HTMLDivElement>(null)
  return (
    <MainElementContext.Provider value={ref.current}>
      <div className="relative flex grow overflow-hidden" ref={ref}>
        <div className="flex grow flex-col overflow-y-auto overflow-x-hidden">
          {children}
        </div>
      </div>
    </MainElementContext.Provider>
  )
}

export function useMainElement(): HTMLElement | null {
  return useContext(MainElementContext)
}

type PageHeaderProps = PropsWithChildren<{
  background?: string
  title?: string
}>

function PageHeader(props: PageHeaderProps) {
  const {background = 'bg-white', children, title} = props
  return (
    <div
      className={clsx(
        'flex-none border-b border-gray-100 px-2 sm:px-8',
        background,
      )}
    >
      <Dialog.Root>
        <div className="flex flex-col gap-y-6 py-6">
          <Dialog.Trigger className="primaryLink flex items-center gap-x-0.5 self-start py-1 md:hidden">
            <ChevronRightIcon aria-hidden className="size-4" />
            <span>Forms</span>
          </Dialog.Trigger>

          <h1 className="text-2xl font-bold text-secondary">{title}</h1>
        </div>

        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-10 bg-black/50" />
          <Dialog.Content
            aria-describedby={undefined}
            className="absolute inset-y-0 left-0 z-10 w-[85%] overflow-y-auto rounded-r-lg border-s border-gray-100 bg-white pt-4 animate-in slide-in-from-left data-[state=closed]:animate-out data-[state=closed]:slide-out-to-left"
          >
            <Dialog.Title className="sr-only">All Forms</Dialog.Title>

            <FormSidebar noAddButton />

            <Dialog.Close className="absolute right-2 top-2 flex-none rounded-lg text-gray-400 hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2">
              <span className="sr-only">Close</span>
              <XIcon aria-hidden className="h-6 w-6" />
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {children}
    </div>
  )
}

function Content(props: PropsWithChildren) {
  const {children} = props
  // Extra bottom padding to clear form button
  return <div className="grow bg-gray-50 pb-16">{children}</div>
}

DashboardLayout.Sidebar = Sidebar
DashboardLayout.Main = Main
DashboardLayout.PageHeader = PageHeader
DashboardLayout.Content = Content

export default DashboardLayout
