/** @format */

import React from 'react'
import {useLocation} from 'react-router-dom'

import Nav, {NavLinkItem} from '@src/components/tailwind/Nav'

import {useProjectsContext} from '@src/contexts'

export function ProjectPageNav() {
  const location = useLocation()
  const {projects} = useProjectsContext()
  const currentProject = projects.find(
    p => p.id === (location.pathname.match(/^\/projects\/(\d+)/) || [])[1],
  )

  if (!currentProject) return null

  return (
    <Nav>
      <NavLinkItem to={`/projects/${currentProject.id}/overview`}>
        Overview
      </NavLinkItem>
      <NavLinkItem to={`/projects/${currentProject.id}/settings`}>
        Settings
      </NavLinkItem>
      <NavLinkItem to={`/projects/${currentProject.id}/templates`}>
        Templates
      </NavLinkItem>
    </Nav>
  )
}
