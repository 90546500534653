/** @format */

import {PlusCircleIcon} from '@heroicons/react/solid'
import {useDebouncedState} from '@mantine/hooks'
import React from 'react'

import {useModalContext} from '@src/components/tailwind/ModalContext'

import {useAllFormsContext, useProjectsContext} from '@src/contexts'

import {AddButtonCombo, AddProjectButton} from './AddButton'
import {CreateProjectModal} from './CreateProjectModal'
import {EmptyState} from './EmptyState'
import {FormsByProjectGroup, NoProjectFormsGroup} from './FormsByProjectGroup'
import {FormSearch} from './FormSearch'
import {useFormSidebarGroups} from './useFormSidebarGroups'

type Props = {
  noAddButton?: boolean
}

export function FormSidebar(props: Props) {
  const {noAddButton} = props
  const {forms} = useAllFormsContext()
  const {dashboardProjects, projects} = useProjectsContext()

  const [searchTerm, setSearchTerm] = useDebouncedState('', 240)
  const {groups, noProjectForms} = useFormSidebarGroups(searchTerm)

  if (forms.length === 0 && projects.length === 0) {
    return (
      <nav className="flex flex-col gap-y-6 py-6">
        <EmptyAccount />
      </nav>
    )
  }

  return (
    <nav className="flex flex-col gap-y-6 py-6">
      {!noAddButton && (
        <div className="px-4">
          {dashboardProjects.length > 0 ? (
            <AddButtonCombo />
          ) : (
            <AddProjectButton />
          )}
        </div>
      )}
      {forms.length >= 10 && (
        <div className="px-4">
          <FormSearch onChange={setSearchTerm} />
        </div>
      )}
      {groups.map(g => (
        <FormsByProjectGroup {...g} />
      ))}
      {noProjectForms.length > 0 && (
        <NoProjectFormsGroup forms={noProjectForms} />
      )}
    </nav>
  )
}

function EmptyAccount() {
  const {openModal} = useModalContext()
  const modal = 'CreateProjectModal:EmptyAccount'
  return (
    <EmptyState testid="FormSidebar:EmptyAccount">
      <CreateProjectModal modal={modal} />
      <div className="flex items-center justify-center gap-x-1 text-sm font-normal text-gray-600">
        <span>Click</span>
        <button
          aria-label="Add project button"
          onClick={() => openModal(modal)}
          type="button"
        >
          <PlusCircleIcon className="h-7 w-7 shrink-0 text-primary" />
        </button>
        <span>to create a project.</span>
      </div>
    </EmptyState>
  )
}
