/** @format */

import React, {ComponentPropsWithoutRef, FC} from 'react'

const Sidebar: FC<{
  pathOverride?: string
  [x: string]: any
}> & {
  GroupHeader: typeof GroupHeader
  Item: typeof Item
  Spacer: typeof Spacer
} = ({pathOverride, children, ...rest}) => {
  return <aside {...rest}>{children}</aside>
}

const GroupHeader: FC<{
  selected?: boolean
  selectable?: boolean
  chrome?: string
  [x: string]: any
}> = ({
  selected = false,
  selectable = true,
  chrome = 'px-4',
  children,
  ...rest
}) => {
  return (
    <div
      className={`w-full ${chrome} ${
        selected
          ? 'border-l-2 border-primary bg-gray-50'
          : `border-l-2 border-transparent ${
              selectable ? 'hover:border-gray-400' : ''
            }`
      }`}
    >
      <div
        className={`overflow-hidden text-ellipsis p-2 text-xs font-medium uppercase tracking-wider text-gray-500`}
        {...rest}
      >
        {children}
      </div>
    </div>
  )
}

const Item: FC<{
  selected?: boolean
  selectable?: boolean
  enabled?: boolean
  icon?: React.ComponentType<ComponentPropsWithoutRef<'svg'>>
  detail?: React.ReactNode
  centered?: boolean
  chrome?: string
  [x: string]: any
}> = ({
  selected = false,
  selectable = true,
  enabled = true,
  chrome = 'px-4',
  icon: Icon,
  centered,
  children,
  ...rest
}) => {
  return (
    <div
      className={`w-full ${chrome} ${
        selected
          ? 'border-l-2 border-primary bg-gray-50'
          : `border-l-2 border-transparent ${
              selectable ? 'hover:border-gray-400' : ''
            }`
      } ${enabled ? '' : 'cursor-not-allowed'} `}
    >
      <div
        className={`flex ${
          centered ? 'justify-center' : 'justify-between'
        } gap-4 p-2`}
        {...rest}
      >
        <div className={'flex items-center overflow-hidden text-sm font-bold'}>
          {Icon && (
            <div
              className={`${
                selected
                  ? 'text-primary group-hover:text-primary'
                  : 'text-gray-400 group-hover:text-gray-500'
              } mr-2 h-6 w-6 shrink-0`}
              aria-hidden="true"
            >
              <Icon />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

function Spacer({chrome = 'h-6', ...rest}: {[x: string]: any}) {
  return <div className={`w-full ${chrome}`} {...rest} />
}

Sidebar.GroupHeader = GroupHeader
Sidebar.Item = Item
Sidebar.Spacer = Spacer

export default Sidebar
