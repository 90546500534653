/** @format */

import {
  CogIcon,
  CreditCardIcon,
  GlobeIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import React, {useMemo} from 'react'
import {NavLink, useLocation, withRouter} from 'react-router-dom'

import Sidebar from '@src/components/tailwind/Sidebar'
import Spinner from '@src/components/tailwind/Spinner'

import {getPermissions} from '@src/accounts/utils'
import {useAccountContext} from '@src/contexts'
import {AccessLevel} from '@src/enums'
import {Permissions} from '@src/types/Account'

import {FormSidebar} from './FormSidebar'

const MainSidebar: React.FC<{
  location: any
  mobile?: boolean
  active?: boolean
}> = ({mobile = false, active = true, location}) => {
  const {projects, memberships} = useAccountContext()
  const permissions = getPermissions(memberships)

  return (
    <Sidebar
      className={mobile ? 'mb-6 border-b' : null}
      data-testid="main-sidebar"
    >
      {location.pathname.match(`^\/account`) ? (
        <>
          <Sidebar.Spacer />
          <AccountMenu
            active={active}
            mobile={mobile}
            permissions={permissions}
          />
        </>
      ) : projects ? (
        <FormSidebar />
      ) : (
        <Spinner />
      )}
    </Sidebar>
  )
}

function AccountMenu({
  active,
  mobile,
  permissions,
}: {
  active?: boolean
  mobile?: boolean
  permissions: Permissions
}) {
  const location = useLocation()
  const accountNavigation = useMemo(
    () => [
      {
        name: 'Account',
        href: '/account',
        icon: CogIcon,
        enabled: permissions.accounts.account.access !== AccessLevel.NONE,
        current: location.pathname.match(/^\/account\/?$/),
      },
      {
        name: 'Team',
        href: '/account/team',
        icon: UserGroupIcon,
        enabled: permissions.accounts.team.access !== AccessLevel.NONE,
        current: location.pathname.match(/^\/account\/team\/?/),
      },
      {
        name: 'Billing',
        href: '/account/billing',
        icon: CreditCardIcon,
        enabled: permissions.accounts.billing.access !== AccessLevel.NONE,
        current: location.pathname.match(/^\/account\/billing\/?/),
      },
      {
        name: 'Domains',
        href: '/account/domains',
        icon: GlobeIcon,
        enabled: permissions.accounts.domain.access !== AccessLevel.NONE,
        current: location.pathname.match(/^\/account\/domains\/?/),
      },
    ],
    [
      location.pathname,
      permissions.accounts.account.access,
      permissions.accounts.billing.access,
      permissions.accounts.domain.access,
      permissions.accounts.team.access,
    ],
  )

  return (
    <div data-testid="account-sidebar">
      {accountNavigation.map(item => (
        <NavLink
          key={item.name}
          to={item.href}
          aria-current={item.current ? 'page' : undefined}
          className={item.enabled ? '' : 'pointer-events-none'}
        >
          <Sidebar.Item
            icon={item.icon}
            centered={mobile}
            selected={!!item.current && active}
            enabled={!!item.enabled}
          >
            <span
              className={item.enabled ? 'truncate' : 'truncate text-gray-400'}
            >
              {item.name}
            </span>
          </Sidebar.Item>
        </NavLink>
      ))}
    </div>
  )
}

export default withRouter(MainSidebar)
