/** @format */

import * as Sentry from '@sentry/react'
import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {FullPageLoading} from '@src/components/tailwind/Loading'

import {AllFormsContextProvider} from '@src/contexts/AllFormsContext'
import {FormApiProvider} from '@src/contexts/FormApi'
import {ProjectsContextProvider} from '@src/contexts/ProjectsContext'

const FormPage = React.lazy(() => import('@src/forms/FormPage'))

export default function IFrameRouter() {
  return (
    <AllFormsContextProvider>
      <ProjectsContextProvider>
        <Sentry.ErrorBoundary>
          <React.Suspense fallback={<FullPageLoading />}>
            <Switch>
              <Route
                path="/iframe/forms/:hashid"
                render={({match}) => (
                  <FormApiProvider hashid={match.params.hashid}>
                    <FormPage />
                  </FormApiProvider>
                )}
              />
            </Switch>
          </React.Suspense>
        </Sentry.ErrorBoundary>
      </ProjectsContextProvider>
    </AllFormsContextProvider>
  )
}
