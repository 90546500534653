/** @format */

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'

import {useModalContext} from '@src/components/tailwind/ModalContext'

import {useCurrentForm, useProjectsContext} from '@src/contexts'
import {Project} from '@src/types/Project'

import {CreateFormModal} from './CreateFormModal'
import {CreateProjectModal} from './CreateProjectModal'
import {Menu, MenuItem} from './Menu'

const CREATE_FORM_MODAL = 'FormSidebar.CreateFormModal'
const CREATE_PROJECT_MODAL = 'FormSidebar.CreateProjectModal'

export function AddButtonCombo() {
  const currentProject = useCurrentProject()
  const {isOpen, openModal} = useModalContext()
  const isModalOpen = isOpen(CREATE_FORM_MODAL) || isOpen(CREATE_PROJECT_MODAL)
  return (
    <>
      <CreateFormModal
        currentProject={currentProject}
        modal={CREATE_FORM_MODAL}
      />
      <CreateProjectModal modal={CREATE_PROJECT_MODAL} />
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          className={clsx(
            'flex w-full select-none items-center justify-center gap-x-2.5 whitespace-nowrap rounded border border-gray-200 px-4 py-2.5 hover:bg-gray-50 focus:outline-none focus-visible:border-blue-700 focus-visible:ring-1 focus-visible:ring-blue-700 data-[state=open]:border-transparent data-[state=open]:bg-primary/10 data-[state=open]:text-primary',
            isModalOpen && 'border-transparent bg-primary/10 text-primary',
          )}
        >
          <AddIcon className="h-5 w-5" />
          <span className="font-semibold">Add New</span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            className="w-[--radix-dropdown-menu-trigger-width] animate-slide-in-down rounded border bg-white py-2.5 drop-shadow"
            onCloseAutoFocus={e => e.preventDefault()}
            sideOffset={4}
          >
            <Menu>
              <MenuItem onSelect={() => openModal(CREATE_FORM_MODAL)}>
                New Form
              </MenuItem>
              <MenuItem onSelect={() => openModal(CREATE_PROJECT_MODAL)}>
                New Project
              </MenuItem>
            </Menu>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  )
}

export function AddProjectButton() {
  const modal = CREATE_PROJECT_MODAL
  const {isOpen, openModal} = useModalContext()
  return (
    <>
      <CreateProjectModal modal={modal} />
      <button
        aria-label="Add project button"
        className={clsx(
          'flex w-full items-center justify-center gap-x-2.5 rounded border border-gray-200 px-4 py-2.5',
          isOpen(modal) && 'border-transparent bg-primary/10 text-primary',
        )}
        onClick={() => openModal(modal)}
      >
        <AddIcon className="h-5 w-5" />
        <span className="font-semibold">Add New</span>
      </button>
    </>
  )
}

type AddIconProps = {
  className: string
}

function AddIcon(props: AddIconProps) {
  const {className} = props
  return (
    <svg
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256 112v288m144-144H112"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </svg>
  )
}

function useCurrentProject(): Project {
  const projectRouteMatch = useRouteMatch<{pid: string}>('/projects/:pid')

  const currentForm = useCurrentForm()
  const {dashboardProjects, getProjectById} = useProjectsContext()
  const [fallback] = dashboardProjects
  if (fallback == null) {
    throw new Error(
      'Cannot find the current project: no dashboard projects found.',
    )
  }

  // Provide default project selection based on the current route
  function getDefaultProject(): Project {
    if (projectRouteMatch) {
      const project = getProjectById(projectRouteMatch.params.pid)
      // Only allow adding form to dashboard projects
      return project?.kind === 'dashboard' ? project : fallback
    }
    if (currentForm) {
      const project = getProjectById(currentForm.project_id)
      // Only allow adding form to dashboard projects
      return project?.kind === 'dashboard' ? project : fallback
    }
    return fallback
  }

  const [value, setValue] = useState<Project>(getDefaultProject)

  useEffect(() => {
    setValue(getDefaultProject())
  }, [currentForm?.project_id, projectRouteMatch?.params.pid])

  return value
}
