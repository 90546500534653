/** @format */

import * as Sentry from '@sentry/react'
import React, {useMemo} from 'react'
import {Redirect, Route, useLocation, useRouteMatch} from 'react-router-dom'

import Banner from '@src/components/tailwind/Banner'
import DashboardLayout from '@src/components/tailwind/DashboardLayout'
import {ErrorState} from '@src/components/tailwind/ErrorState'
import {ModalContextProvider} from '@src/components/tailwind/ModalContext'

import {AccountSwitcher} from '@src/accounts/AccountSwitcher'
import ajax from '@src/ajax'
import {
  useAccountContext,
  useAllFormsContext,
  useCurrentForm,
  useProjectsContext,
} from '@src/contexts'
import {AllFormsContextProvider} from '@src/contexts/AllFormsContext'
import {ProjectsContextProvider} from '@src/contexts/ProjectsContext'
import FormPageNav from '@src/forms/FormPage/FormPageNav'
import {ProjectPageNav} from '@src/forms/ProjectPage/ProjectPageNav'
import Portal from '@src/Portal'
import {getDefaultFormPageUrl, getFormName} from '@src/utils'

import DashboardRouter from './DashboardRouter'
import IFrameWrapper from './IFrameWrapper'
import MainSidebar from './MainSidebar'
import {DesktopNavMenu, MobileNavMenu} from './NavMenu'
import {OnboardingSurveyModal} from './OnboardingSurveyModalV0'

function Dashboard() {
  const {account} = useAccountContext()
  const currentForm = useCurrentForm()
  const location = useLocation()
  const {projects} = useProjectsContext()

  const isFormPage = !!useRouteMatch('/forms')
  const isProjectPage = !!useRouteMatch('/projects')
  // These are served by iframe, see forms/FormPage/index.js (non-tailwind)
  const isLegacyFormPage = !!useRouteMatch('/forms/:hashid/whitelabel')

  const isRedesign =
    location.pathname.match(
      /\/(admin|dashboard|plans|account|login|team|support)(\/.*)?/,
    ) ||
    (isFormPage && !isLegacyFormPage) ||
    isProjectPage

  const showHeader = !location.pathname.match(/\/(login)\/?/)

  const currentProjectTitle = projects.find(
    p => p.id === (location.pathname.match(/^\/projects\/(\d+)/) || [])[1],
  )?.name

  return (
    <ModalContextProvider>
      {account && showHeader && (
        <>
          <Portal to="#teams-list">
            <Sentry.ErrorBoundary
              beforeCapture={scope => {
                scope.setTag('location', 'account-switcher')
              }}
              fallback={({error}) => <ErrorState error={error} />}
            >
              <AccountSwitcher />
            </Sentry.ErrorBoundary>
          </Portal>

          <Portal to="#mobile-nav-menu">
            <MobileNavMenu />
          </Portal>

          <Portal to="#desktop-nav-menu">
            <DesktopNavMenu />
          </Portal>
        </>
      )}

      <OnboardingSurveyModal />

      <DashboardLayout>
        <DashboardLayout.Sidebar>
          <MainSidebar />
        </DashboardLayout.Sidebar>
        <DashboardLayout.Main>
          <NotificationBanners />

          {isFormPage && currentForm && (
            <DashboardLayout.PageHeader title={getFormName(currentForm)}>
              <FormPageNav currentForm={currentForm} />
            </DashboardLayout.PageHeader>
          )}
          {isProjectPage && (
            <DashboardLayout.PageHeader title={currentProjectTitle}>
              <ProjectPageNav />
            </DashboardLayout.PageHeader>
          )}
          <DashboardLayout.Content>
            {isRedesign ? <DashboardRouter /> : <IFrameWrapper />}
          </DashboardLayout.Content>
        </DashboardLayout.Main>
      </DashboardLayout>
    </ModalContextProvider>
  )
}

function NotificationBanners() {
  const {account, updateAccount} = useAccountContext()
  const {notifications} = account

  if (notifications.length === 0) {
    return null
  }

  return (
    <div className="flex-none">
      {notifications.map(n => {
        if (n.priority === 0) {
          return (
            <Banner
              key={n.id}
              level={n.data.level || 'info'}
              title={n.data.title}
              text={n.data.text}
              deletable={n.data.deletable}
              link_to={n.data.link_to}
              button_text={n.data.button_text}
              onDismiss={() => {
                ajax({
                  method: 'DELETE',
                  endpoint: `/api-int/notifications/${n.id}`,
                  onSuccess: async () => {
                    const updatedNotifications = notifications.filter(
                      el => el.id !== n.id,
                    )
                    updateAccount(prev => {
                      const updated = {...prev}
                      updated.account.notifications = updatedNotifications
                      return updated
                    })
                  },
                })
              }}
            />
          )
        }
      })}
    </div>
  )
}

export function CurrentFormResolver() {
  const {forms} = useAllFormsContext()
  const location = useLocation()
  const {projects} = useProjectsContext()
  const {hashid, projid} = useMemo(
    () => ({
      hashid: (location.pathname.match(/^\/forms\/(.+?)(\/.*)?$/) || [])[1],
      projid: (location.pathname.match(/^\/projects\/(.+?)(\/.*)?$/) || [])[1],
    }),
    [location.pathname],
  )

  // validHashId is either the current hashid if valid, or --
  // if in mobile: undefined (show the /forms page)
  // if on desktop: the last valid hashid, or any valid hashid if possible
  const validHashId = useMemo(() => {
    // returns undefined if the hashid doesn't reference a form
    function checkHashId(id: string | null): string | null | undefined {
      return !!forms.filter(f => f.hashid === id).length ? id : undefined
    }

    let newId = checkHashId(hashid)

    // Some devices (e.g. Chrome Mobile WebView) don't have localStorage enabled
    // so we need to check if localStorage is available first.
    if (localStorage && !newId) {
      newId = checkHashId(localStorage.getItem('currentHashid'))
    }

    if (!newId && forms.length > 0) {
      newId = forms[0].hashid
    }
    if (localStorage) {
      if (newId) {
        localStorage.setItem('currentHashid', newId)
      } else {
        localStorage.removeItem('currentHashid')
      }
    }
    return newId
  }, [forms, hashid])

  // if the URL contains a bad hashid or bad projid, redirect
  if (
    (location.pathname.match(/^\/forms\/?/) && hashid !== validHashId) ||
    (location.pathname.match(/^\/projects\/?/) &&
      !projects.filter(p => p.id === projid).length)
  ) {
    const form = forms.find(f => f.hashid === validHashId)
    return form ? (
      <Redirect to={getDefaultFormPageUrl(form)} />
    ) : (
      <Redirect to="/forms" />
    )
  }

  return <Dashboard />
}

export default () => {
  return (
    <AllFormsContextProvider>
      <ProjectsContextProvider>
        <Route component={CurrentFormResolver} />
      </ProjectsContextProvider>
    </AllFormsContextProvider>
  )
}
