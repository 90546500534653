/** @format */

import clsx from 'clsx'
import React, {useId, useMemo} from 'react'
import Select, {
  GroupBase,
  Props as ReactSelectProps,
  StylesConfig,
  Theme,
} from 'react-select'

type Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = {
  label?: string
  testid?: string
} & Omit<
  ReactSelectProps<Option, IsMulti, Group>,
  'inputId' | 'menuPortalTarget' | 'menuPosition' | 'styles' | 'theme'
>

export function FormspreeSelect<
  Option = {
    isDisabled?: boolean
    label: string
    value: string
  },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  const {label, testid, ...selectProps} = props
  const id = useId()
  const customStyles = useMemo(
    (): StylesConfig<Option, IsMulti, Group> => ({
      menu: provided => ({
        ...provided,
        zIndex: '1000',
      }),
      menuPortal: provided => ({...provided, zIndex: 1000}),
      control: provided => ({
        ...provided,
        minHeight: '44px',
        borderStyle: 'none',
        borderWidth: '0px',
        borderRadius: '4px',
        padding: '0',
      }),
      input: provided => ({...provided, boxShadow: 'none'}),
    }),
    [],
  )

  return (
    <div
      className={clsx(
        'formspree-select-wrapper',
        label && 'flex flex-col gap-y-2',
      )}
      data-testid={testid}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        {...selectProps}
        inputId={id}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={customStyles}
        theme={customTheme}
      />
    </div>
  )
}

function customTheme(original: Theme): Theme {
  return {
    ...original,
    colors: {
      ...original.colors,
      primary: '#aaa',
      primary25: '#ddd',
      primary50: '#ddd',
      danger: original.colors.neutral80,
      dangerLight: '#ddd',
      neutral0: 'rgb(243, 244, 246)',
    },
  }
}

type Key = string | number | boolean

type UseSingleSelectStateProps<K extends Key, T> = {
  collection: readonly T[]
  onSelectedKeyChange: (key: K | null) => void
  selectedKey?: K | null
  toOption: (v: T) => Option<K>
}

type SingleSelectState<K extends Key> = {
  onChange: (selection: Option<K> | null) => void
  options: Option<K>[]
  // name this value rather than selection so we can just spread the state
  // to FormspreeSelect as props
  value: Option<K> | null
}

export type Option<K> = {
  label: string
  value: K
}

// due to https://github.com/JedWatson/react-select/issues/5032
// we cannot just pass string[] to react-select
//
// this function helps working with it easier by translating key-based state
// to option-based state (reference) required by react-select
//
// Note: the value is not re-calculated when getKey and toOption props changed
// so make sure those functions are pure (don't rely on scoped variables).
export function useSingleSelectState<K extends Key, T>(
  props: UseSingleSelectStateProps<K, T>,
): SingleSelectState<K> {
  const {collection, onSelectedKeyChange, selectedKey, toOption} = props
  const {byKey, options} = useMemo(() => {
    const byKey: Map<K, Option<K>> = new Map()
    const options: Option<K>[] = []
    for (const item of collection) {
      const o = toOption(item)
      byKey.set(o.value, o)
      options.push(o)
    }
    return {byKey, options}
  }, [collection, toOption])

  function onChange(selection: Option<K> | null): void {
    onSelectedKeyChange(selection?.value ?? null)
  }

  const selection =
    selectedKey != null ? (byKey.get(selectedKey) ?? null) : null
  return {
    onChange,
    options,
    value: selection,
  }
}

export function defaultToOption<K extends Key>(v: K): Option<K> {
  return {
    label: `${v}`,
    value: v,
  }
}

export function identityToOption<K extends Key>(o: Option<K>): Option<K> {
  return o
}
