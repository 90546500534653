/** @format */

import {Menu, Transition} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon, PlusIcon} from '@heroicons/react/solid'
import clsx from 'clsx'
import React, {Fragment, useMemo} from 'react'
import {Link, useHistory} from 'react-router-dom'

import {useModalContext} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {useAccountContext} from '@src/contexts'
import {Membership, TeamMembership, UserMembership} from '@src/types/Account'

import CreateTeamModal from './teams/CreateTeamModal'

export function AccountSwitcher() {
  const memberships = useMembershipsByKind()
  const {openModal} = useModalContext()
  return (
    <>
      <CreateTeamModal />
      <Menu as="div" className="relative inline-block text-left">
        {({open}) => (
          <>
            <Menu.Button className="focus-visible:ring-light-blue inline-flex max-w-2xs rounded border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 md:max-w-xs lg:max-w-lg">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                {memberships.current.team.name}
              </span>
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute left-0 z-10 mt-2 max-w-xs origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none sm:left-0 sm:origin-center"
                style={{minWidth: '14rem'}}
              >
                {memberships.teams.length > 0 && (
                  <section>
                    <header className="bg-gray-50 px-4 py-1">
                      <h3 className="text-xs font-semibold text-gray-700">
                        Team accounts
                      </h3>
                    </header>
                    <ul>
                      {memberships.teams.map(m => {
                        const MenuItem = m.disabled
                          ? DisabledMenuItem
                          : m.status === 'pending'
                            ? PendingMenuItem
                            : EnabledMenuItem

                        return (
                          <li key={m.membership_id || m.team.name}>
                            <MenuItem membership={m} />
                          </li>
                        )
                      })}
                    </ul>
                  </section>
                )}

                <section>
                  <header className="bg-gray-50 px-4 py-1">
                    <h3 className="text-xs font-semibold text-gray-700">
                      Personal account
                    </h3>
                  </header>
                  <EnabledMenuItem membership={memberships.user} />
                </section>

                <Menu.Item>
                  {({active}) => (
                    <div
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex cursor-pointer items-center rounded px-4 py-2 text-sm',
                      )}
                      onClick={() => openModal('CREATE_TEAM')}
                      role="button"
                    >
                      <PlusIcon className="mr-1 inline-flex h-4 w-4" /> Add team
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  )
}

type MembershipsByKind = {
  current: Membership
  teams: TeamMembership[]
  user: UserMembership
}

function useMembershipsByKind(): MembershipsByKind {
  const {memberships} = useAccountContext()
  return useMemo(() => {
    let current: Membership | undefined
    const teams: TeamMembership[] = []
    let user: UserMembership | undefined

    for (const m of memberships) {
      if (m.kind === 'team') {
        teams.push(m)
      } else {
        user = m
      }

      if (m.current) {
        current = m
      }
    }

    if (!current) {
      throw new Error('Cannot find current membership.')
    }
    if (!user) {
      throw new Error('Cannot find user membership.')
    }

    return {current, teams, user}
  }, [memberships])
}

type EnabledMenuItemProps = {
  membership: Membership
}

function EnabledMenuItem(props: EnabledMenuItemProps) {
  const {membership} = props
  const {reloadAccount} = useAccountContext()
  const history = useHistory()

  function handleClick() {
    if (membership.current) {
      return
    }

    ajax({
      method: 'POST',
      endpoint: `/api-int/account/switch`,
      payload:
        membership.kind === 'team'
          ? {membership_id: membership.membership_id}
          : {},
      errorMsg: 'Failed to switch accounts',
      onSuccess: () => {
        reloadAccount()
        if (
          (window.location.href.indexOf('/forms') !== -1 &&
            !window.location.href.endsWith('/forms')) ||
          window.location.href.indexOf('/invite/confirm') !== -1
        ) {
          history.push('/forms')
        }
      },
    })
  }

  return (
    <Menu.Item>
      {({active}) => (
        <div
          className={clsx(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'flex cursor-pointer items-center rounded px-4 py-2 text-sm',
          )}
          onClick={handleClick}
          role="button"
        >
          <span className="mr-1 inline-flex h-4 w-4 shrink-0">
            {membership.current && (
              <CheckIcon className="object-fill text-primary" />
            )}
          </span>
          <span className="grow">{membership.team.name}</span>
        </div>
      )}
    </Menu.Item>
  )
}

type DisabledMenuItemProps = {
  membership: TeamMembership
}

function DisabledMenuItem(props: DisabledMenuItemProps) {
  const {membership} = props
  return (
    <Menu.Item disabled>
      {() => (
        <div
          className="flex cursor-default items-center rounded px-4 py-2 text-sm text-gray-500"
          role="button"
        >
          <span className="mr-1 inline-flex h-4 w-4 shrink-0">
            {membership.current && <CheckIcon className="object-fill" />}
          </span>
          <span className="grow">{membership.team.name}</span>
          <span className="ml-2 inline-flex shrink-0">
            <MenuItemBadge label="Locked" status="error" />
          </span>
        </div>
      )}
    </Menu.Item>
  )
}

type PendingMenuItemProps = {
  membership: TeamMembership
}

function PendingMenuItem(props: PendingMenuItemProps) {
  const {membership} = props
  return (
    <Menu.Item>
      {({active}) => (
        <Link
          to={`/invite/confirm?id=${membership.membership_id}`}
          className={clsx(
            'flex cursor-pointer items-center rounded px-4 py-2 text-sm',
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          )}
          role="button"
        >
          <span className="mr-1 inline-flex h-4 w-4 shrink-0">
            {membership.current && (
              <CheckIcon className="object-fill text-primary" />
            )}
          </span>
          <span className="grow">{membership.team.name}</span>
          <span className="ml-2 inline-flex shrink-0">
            <MenuItemBadge label="Pending" status="warning" />
          </span>
        </Link>
      )}
    </Menu.Item>
  )
}

type MenuItemBadgeProps = {
  label: string
  status: 'warning' | 'error'
}

function MenuItemBadge(props: MenuItemBadgeProps) {
  const {label, status} = props
  return (
    <span
      className={clsx(
        'inline-flex select-none items-center rounded px-2 py-0.5 text-xs font-medium',
        status === 'error'
          ? 'bg-red-100 text-red-800'
          : 'bg-yellow-100 text-yellow-800',
      )}
    >
      {label}
    </span>
  )
}
