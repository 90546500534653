/** @format */

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React, {ReactElement} from 'react'

type MenuProps = {
  children: Iterable<ReactElement<MenuItemProps> | boolean | null | undefined>
}

export function Menu(props: MenuProps) {
  const {children} = props
  return <div className="flex flex-col items-stretch gap-y-1">{children}</div>
}

type MenuItemProps = {
  children: string
  onSelect: DropdownMenu.DropdownMenuItemProps['onSelect']
}

export function MenuItem(props: MenuItemProps) {
  const {children, onSelect} = props
  return (
    <DropdownMenu.Item
      className="cursor-pointer select-none whitespace-nowrap px-4 py-2.5 text-start text-sm font-semibold hover:bg-gray-100 focus:outline-none focus-visible:bg-gray-100"
      onSelect={onSelect}
    >
      {children}
    </DropdownMenu.Item>
  )
}
