/** @format */

import {LogoutIcon} from '@heroicons/react/outline'
import {MenuIcon} from '@heroicons/react/solid'
import * as Dialog from '@radix-ui/react-dialog'
import React, {useState} from 'react'
import {Link, useRouteMatch} from 'react-router-dom'

import {NotificationDropdown} from '@src/components/tailwind/NotificationDropdown'

import {getPermissions} from '@src/accounts/utils'
import {useAccountContext} from '@src/contexts'
import {AccessLevel} from '@src/enums'
import type {Permissions} from '@src/types/Account'

import MainSidebar from './MainSidebar'

export function DesktopNavMenu() {
  const {account, memberships} = useAccountContext()
  const permissions: Permissions = getPermissions(memberships)
  const accountPage = getAccountPage(permissions)
  return (
    <>
      <Link
        className="primaryLink flex items-center p-5.5 font-bold"
        to="/forms"
      >
        Forms
      </Link>
      <Link
        className="primaryLink flex items-center p-5.5 font-bold"
        to={accountPage}
      >
        Account
      </Link>
      <div className="relative">
        <NotificationDropdown notifications={account.notifications} />
      </div>
      <a className="flex items-center p-5.5 text-primary" href="/logout">
        <LogoutIcon className="h-6 w-6" />
      </a>
    </>
  )
}

export function MobileNavMenu() {
  const {account, memberships} = useAccountContext()
  const permissions: Permissions = getPermissions(memberships)
  const accountPage = getAccountPage(permissions)
  const isAccountPage = !!useRouteMatch('/account')
  const [open, setOpen] = useState(false)

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="p-5.5">
        <MenuIcon className="size-6" />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content
          aria-describedby={undefined}
          className="absolute inset-0 z-10 overflow-y-auto bg-gray-100 duration-200 animate-in fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out"
          onClick={() => setOpen(false)}
        >
          <div className="flex flex-col items-center">
            <Dialog.Title className="sr-only">Mobile Menu</Dialog.Title>

            <Dialog.Close className="mx-auto px-5.5 py-7">
              <svg
                className="size-6"
                fill="currentColor"
                viewBox="0 0 21 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.125 2.814 18.186.875 10.5 8.561 2.814.875.875 2.814 8.561 10.5.875 18.186l1.939 1.939 7.686-7.686 7.686 7.686 1.939-1.939-7.686-7.686z" />
              </svg>
            </Dialog.Close>

            {isAccountPage && (
              <div className="w-full">
                <MainSidebar />
              </div>
            )}

            <Link className="primaryLink px-5.5 py-2 font-bold" to="/forms">
              Forms
            </Link>
            <Link
              className="primaryLink px-5.5 py-2 font-bold"
              to={accountPage}
            >
              Account
            </Link>
            <div
              className="relative"
              onClick={event => event.stopPropagation()}
            >
              <NotificationDropdown notifications={account.notifications} />
            </div>
            <a className="px-5.5 py-2 text-primary" href="/logout">
              <LogoutIcon className="h-6 w-6" />
            </a>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

/**
 * Get the path to the first account page that the user has access to
 * @type {string} path
 */
function getAccountPage(permissions: Permissions) {
  const permission = Object.entries(permissions.accounts).find(
    ([key, val]) => key !== 'access' && val.access !== AccessLevel.NONE,
  )
  if (permission) {
    const [, {path}] = permission
    return path ? `/account/${path}` : '/account'
  }
  return '/account'
}
