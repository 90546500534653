/** @format */

import {Menu} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import clsx from 'clsx'
import React from 'react'

import {capitalize} from '../../utils'

export default function NotificationItem(props) {
  const renderFailedPluginNotification = () => {
    return (
      <>
        <button
          className={
            'items-top mtext-gray-700 z-20 block flex w-full border-b px-4 py-2 text-left text-sm hover:bg-gray-100'
          }
        >
          <div
            className={clsx(
              props.notification.read ? 'bg-white-500' : 'bg-blue-500',
              'mr-2 mt-1 flex max-h-2.5 rounded-full border-2 p-1'
            )}
          />
          <div
            className={'w-full'}
            onClick={() => props.readNotification(props.notification)}
          >
            You have {props.notification.data.counter}{' '}
            <b>{props.notification.data.plugin_kind}</b>{' '}
            {props.notification.data.counter > 1 ? 'failures' : 'failure'} on
            form <b>{props.notification.form}</b>.
            <p className={'text-xs text-gray-500'}>
              {props.notification.last_update}
            </p>
          </div>
          <div onClick={() => props.deleteNotification(props.notification.id)}>
            <XIcon className={'z-30 ml-2 h-4 w-4'} />
          </div>
        </button>
      </>
    )
  }

  const renderInternalNotification = () => {
    return (
      <>
        <button
          className={
            'items-top z-20 block flex w-full border-b px-4 py-2 text-left text-sm hover:bg-gray-100'
          }
        >
          <div
            className={clsx(
              props.notification.read ? 'bg-white-500' : 'bg-blue-500',
              'mr-2 mt-1 flex max-h-2.5 rounded-full border-2 p-1'
            )}
          />
          <div
            className={'w-full'}
            onClick={() => props.readNotification(props.notification)}
          >
            {props.notification.data.text}
            <p className={'text-xs text-gray-500'}>
              {props.notification.last_update}
            </p>
          </div>
          <div onClick={() => props.deleteNotification(props.notification.id)}>
            <XIcon className={'z-30 ml-2 h-4 w-4'} />
          </div>
        </button>
      </>
    )
  }

  return (
    <Menu.Item>
      {props.notification.kind === 'failed-plugin' ? (
        renderFailedPluginNotification()
      ) : props.notification.kind === 'internal-notification' ? (
        renderInternalNotification()
      ) : (
        <div>Something went wrong ...</div>
      )}
    </Menu.Item>
  )
}
