/** @format */

import * as Sentry from '@sentry/react'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {ErrorState} from '@src/components/tailwind/ErrorState'
import {FullPageLoading} from '@src/components/tailwind/Loading'

import {useAccountContext} from '@src/contexts'

const Accounts = React.lazy(() => import('@src/accounts'))
const Admin = React.lazy(() => import('@src/Admin'))
const FormList = React.lazy(() => import('@src/forms/FormList'))
const FormPage = React.lazy(
  () => import('@src/forms/FormPage/tailwind/FormPage'),
)
const ProjectPage = React.lazy(() => import('@src/forms/ProjectPage/tailwind'))
const SupportPage = React.lazy(() => import('@src/support'))

export default function DashboardRouter() {
  const {account} = useAccountContext()

  return (
    <React.Suspense fallback={<FullPageLoading />}>
      <Switch>
        <Redirect from="/dashboard" to="/forms" />
        <Route path="/account" component={Accounts} />
        {account.features.admin && (
          <Route exact path="/admin" component={Admin} />
        )}
        <Route path="/forms" component={FormRouter} />
        <Route path="/projects" component={ProjectPage} />
        {account.features.support && (
          <Route path="/support" component={SupportPage} />
        )}
      </Switch>
    </React.Suspense>
  )
}

function FormRouter() {
  return (
    <Sentry.ErrorBoundary fallback={({error}) => <ErrorState error={error} />}>
      <Switch>
        <Route exact path="/forms" component={FormList} />
        <Route path="/forms/:hashid" component={FormPage} />
      </Switch>
    </Sentry.ErrorBoundary>
  )
}
