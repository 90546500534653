/** @format */

import {Switch} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/solid'
import React, {ComponentProps, ReactNode, useId} from 'react'

type CheckboxProps = {
  label: ReactNode
} & CheckboxSwitchProps

export function Checkbox(props: CheckboxProps) {
  const {id = useId(), label, ...switchProps} = props
  return (
    <Switch.Group>
      <div className="flex items-start gap-2">
        <div className="inline-flex py-1">
          <CheckboxSwitch {...switchProps} id={id} />
        </div>
        <label htmlFor={id}>{label}</label>
      </div>
    </Switch.Group>
  )
}

type CheckboxSwitchProps = OwnCheckboxSwitchProps &
  Omit<ComponentProps<typeof Switch>, keyof OwnCheckboxSwitchProps>

type OwnCheckboxSwitchProps = {
  disabled?: boolean
  id?: string
}

export function CheckboxSwitch(props: CheckboxSwitchProps) {
  const {checked, disabled, onChange, ...switchProps} = props
  return (
    <Switch
      {...switchProps}
      checked={checked}
      className={switchClassName(checked, disabled)}
      onChange={disabled ? undefined : onChange}
    >
      {checked ? <CheckIcon className="text-white" /> : null}
    </Switch>
  )
}

function switchClassName(checked?: boolean, disabled?: boolean): string {
  let c = 'shrink-0 inline-flex w-4 h-4 rounded border'
  if (disabled) {
    return `${c} cursor-default border-gray-300 bg-gray-200`
  }

  c = `${c} cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300`
  if (checked) {
    return `${c} border-primary bg-primary`
  }
  return `${c} border-gray-300 hover:border-gray-500`
}
