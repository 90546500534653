/** @format */

import React from 'react'
import {Link, useLocation} from 'react-router-dom'

import Nav from '@src/components/tailwind/Nav'

import {useAccountContext} from '@src/contexts'
import {AccessLevel} from '@src/enums'
import type {Form} from '@src/types/Form'

import {getPermissions} from '../../accounts/utils'

export default function FormPageNav({currentForm}: {currentForm: Form}) {
  const {account, memberships} = useAccountContext()
  const location = useLocation()
  const match = (path: string) => !!location.pathname.match(`\/${path}\/?$`)

  const permissions = getPermissions(memberships)

  // pages with specific order, and enabled/disabled based on features
  const pages = [
    currentForm.counter > 0
      ? {
          name: 'Overview',
          enabled: permissions.forms.overview.access !== AccessLevel.NONE,
        }
      : {
          name: 'Integration',
          enabled: permissions.forms.integration.access !== AccessLevel.NONE,
        },
    {
      name: 'Submissions',
      enabled: permissions.forms.submissions.access !== AccessLevel.NONE,
    },
    {
      name: 'Settings',
      enabled: permissions.forms.settings.access !== AccessLevel.NONE,
    },
  ]

  if (Boolean(account.features.workflow)) {
    pages.push({
      name: 'Workflow',
      enabled: permissions.forms.workflow.access !== AccessLevel.NONE,
    })
  } else {
    pages.push({
      name: 'Plugins',
      enabled: permissions.forms.plugins.access !== AccessLevel.NONE,
    })
  }

  pages.push({
    name: 'Rules',
    enabled: permissions.forms.rules.access !== AccessLevel.NONE,
  })

  if (Boolean(account.features.charts)) {
    pages.push({
      name: 'Analyze',
      enabled: permissions.forms.analyze.access !== AccessLevel.NONE,
    })
  }

  // filter pages based on enabled/disabled and role
  let navItems = pages.map(page => (
    <Nav.Item
      selected={match(page.name.toLowerCase())}
      key={page.name.toLowerCase()}
    >
      <Link
        to={`/forms/${currentForm.hashid}/${page.name.toLowerCase()}`}
        className={
          page.enabled
            ? 'hover:text-gray-900'
            : 'pointer-events-none cursor-not-allowed text-gray-300'
        }
      >
        {page.name}
      </Link>
    </Nav.Item>
  ))

  return <Nav>{navItems}</Nav>
}
