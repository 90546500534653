/** @format */

import {BellIcon} from '@heroicons/react/outline'
import React from 'react'

export default function NotificationIcon({
  size,
  alert = true,
}: {
  size?: string
  alert?: boolean
}) {
  return (
    <div className={`relative ${size}`}>
      <BellIcon className={'h-full w-full'} />
      {alert && (
        <div className={'absolute right-0 top-0'}>
          <div
            className={
              'h-2.5 w-2.5 rounded-full border border-white bg-blue-500'
            }
          />
        </div>
      )}
    </div>
  )
}
