/** @format */

export enum MembershipRole {
  PRIMARY_ADMIN = 'Primary Admin',
  BILLING = 'Billing',
  ADMIN = 'Admin',
  VIEWER = 'Viewer',
  MEMBER = 'Member',
}

export enum AccessLevel {
  WRITE = 'write',
  READ = 'read',
  NONE = 'none',
}
