/** @format */

import {CheckIcon} from '@heroicons/react/outline'
import React from 'react'

export default function ButtonChoices(props) {
  const {label, value, choices, onChange} = props
  return (
    <fieldset>
      <legend>{label}</legend>
      {choices.map(choice => {
        const isSelected = choice.value === value
        return (
          <div
            className={
              'mt-2 w-full cursor-pointer rounded border-2 border-solid p-3 text-sm ' +
              (isSelected
                ? 'border-secondary bg-secondary text-white'
                : 'border-gray-200 hover:bg-gray-200')
            }
            onClick={() => !isSelected && onChange(choice.value, value)}
            key={choice.value}
          >
            <div
              className="flex items-center justify-between text-sm"
              key={choice.value}
            >
              <input
                type="radio"
                name="type"
                id={`type-${choice.value}`}
                checked={isSelected}
                onChange={() => {}}
                className="hidden"
              />
              <label
                className="font-bold leading-5"
                htmlFor={`type-${choice.value}`}
              >
                {choice.label}
              </label>
              {isSelected && <CheckIcon className="h-5 w-5" />}
            </div>
            <p className="mt-2 whitespace-normal normal-case">
              {choice.description}
            </p>
          </div>
        )
      })}
    </fieldset>
  )
}
