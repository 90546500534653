/** @format */

import {ExclamationIcon} from '@heroicons/react/outline'
import React from 'react'

export function ErrorState({error}: {error: Error}) {
  return (
    <div className="pt-12">
      <div className="flex flex-col items-center gap-2">
        <ExclamationIcon className="h-10 w-10 text-gray-700" />
        <h3 className="text-lg">Something went wrong.</h3>
        <p className="text-gray-500">{error.message}</p>
      </div>
    </div>
  )
}
