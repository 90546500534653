/** @format */

import {ExclamationIcon, XIcon} from '@heroicons/react/outline'
import {InformationCircleIcon} from '@heroicons/react/solid'
import clsx from 'clsx'
import React from 'react'
import {HashLink} from 'react-router-hash-link'

import {isValidURL} from '@src/utils'

type BannerProps = {
  button_text?: string
  deletable?: boolean
  level: 'info' | 'warning' | 'error'
  link_to?: string
  onDismiss: () => void
  title: string
  text: string
}

export default function Banner(props: BannerProps) {
  const {button_text, deletable, level, link_to, onDismiss, title, text} = props
  return (
    <div
      role="alert"
      className={clsx(
        level === 'info'
          ? 'bg-blue-50'
          : level === 'warning'
            ? 'bg-yellow-100'
            : 'bg-red-100',
        'px-4 py-3',
      )}
    >
      <div className="flex items-center gap-4">
        {level === 'info' ? (
          <InformationCircleIcon
            className="h-6 w-6 shrink-0 text-blue-400"
            aria-hidden="true"
          />
        ) : (
          <ExclamationIcon
            className={clsx(
              level === 'warning' ? 'text-yellow-400' : 'text-primary',
              'h-6 w-6 shrink-0',
            )}
            aria-hidden="true"
          />
        )}
        <div className="grow">
          <p className="font-bold">{title}</p>
          <p className="text-sm">{text}</p>
        </div>
        {link_to && (
          <div className="flex shrink-0">
            <HashLink
              smooth
              to={isValidURL(link_to) ? {pathname: link_to} : link_to}
              target={isValidURL(link_to) ? '_blank' : ''}
              className={clsx(
                level === 'error' ? 'bg-primary' : 'bg-secondary',
                'm-2 flex items-center justify-center rounded border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:m-0',
              )}
            >
              {button_text
                ? button_text
                : level === 'error'
                  ? 'Fix →'
                  : 'Read more →'}
            </HashLink>
          </div>
        )}
        {deletable && <DismissButton onClick={onDismiss} />}
      </div>
    </div>
  )
}

function DismissButton({onClick}: {onClick: () => void}) {
  return (
    <button
      onClick={onClick}
      className="flex shrink-0 items-center rounded-full p-1 hover:bg-black/5"
    >
      <span className="sr-only">Dismiss</span>
      <XIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}
