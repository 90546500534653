/** @format */

import React, {Fragment, useEffect, useState, useContext} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {AccountContext} from '../../contexts/AccountContext'
import ajax from '../../ajax'
import NotificationItem from './NotificationItem'
import NotificationIcon from './NotificationIcon'
import {useHistory} from 'react-router-dom'
import {isValidURL} from '../../utils'

export const NotificationDropdown = props => {
  const [notifications, setNotifications] = useState(props.notifications)
  const [unreadNotifications, setUnreadNotifications] = useState(false)
  const history = useHistory()
  const {account} = useContext(AccountContext)

  useEffect(() => {
    updateIconStatus(notifications)
  }, [])

  const fetchNotifications = async () => {
    await ajax({
      method: 'GET',
      endpoint: `/api-int/notifications`,
      onSuccess: response => {
        setNotifications(response)
        updateIconStatus(response)
      },
      errorMsg: 'Failed to load notifications'
    })
  }

  const updateIconStatus = notifications => {
    setUnreadNotifications(
      notifications !== undefined &&
        notifications.length > 0 &&
        notifications.filter(
          notification => !notification.read && notification.priority !== 0
        ).length > 0
    )
  }

  const readNotification = async notification => {
    await ajax({
      method: 'PUT',
      endpoint: `/api-int/notifications/read`,
      payload: {notification_id: notification.id},
      onSuccess: () => {
        const updatedNotifications = notifications.map(item => {
          if (item.id === notification.id) {
            return {
              ...item,
              read: true
            }
          } else {
            return item
          }
        })
        account.notifications = updatedNotifications
        setNotifications(updatedNotifications)
        updateIconStatus(updatedNotifications)
      },
      errorMsg: 'Failed to read notification'
    })
    if (notification.data.link_to) {
      if (isValidURL(notification.data.link_to)) {
        window.open(
          notification.data.link_to,
          '_blank',
          'noopener,formspree.io'
        )
      } else {
        history.push(notification.data.link_to)
      }
    }
  }

  const deleteNotification = notification_id => {
    ajax({
      method: 'DELETE',
      endpoint: `/api-int/notifications/${notification_id}`,
      onSuccess: () => {
        setNotifications(
          notifications.filter(
            notification => notification.id !== notification_id
          )
        )
      },
      errorMsg: 'Failed to delete notification'
    })
  }

  const deleteAll = e => {
    e.preventDefault()
    ajax({
      method: 'DELETE',
      endpoint: '/api-int/notifications/delete_all',
      onSuccess: () => {
        setNotifications([])
        setUnreadNotifications(false)
      },
      errorMsg: 'Failed to delete notifications'
    })
  }

  return (
    <Menu>
      <Menu.Button className="px-5.5 md:py-5.5 py-2 text-primary">
        <div onClick={fetchNotifications}>
          <NotificationIcon size="w-6 h-6" alert={unreadNotifications} />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scalebut it-95"
      >
        <Menu.Items className="sm:right-5.5 absolute left-0 right-0 z-50 max-h-[24rem] min-w-80 origin-top-left overflow-auto rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none sm:left-auto sm:-mt-7 sm:origin-top-right md:w-80">
          {notifications &&
          notifications.filter(notification => notification.priority !== 0)
            .length > 0 ? (
            <>
              {notifications
                .filter(
                  notification =>
                    !notification.read && notification.priority !== 0
                )
                .sort((a, b) =>
                  a.last_update < b.last_update || a.priority > b.priority
                    ? 1
                    : -1
                )
                .map(notification => (
                  <NotificationItem
                    notification={notification}
                    deleteNotification={deleteNotification}
                    readNotification={readNotification}
                  />
                ))}
              {notifications
                .filter(
                  notification =>
                    notification.read && notification.priority !== 0
                )
                .sort((a, b) =>
                  a.last_update < b.last_update || a.priority > b.priority
                    ? 1
                    : -1
                )
                .map(notification => (
                  <NotificationItem
                    notification={notification}
                    deleteNotification={deleteNotification}
                    readNotification={readNotification}
                  />
                ))}
              <div>
                <Menu.Item
                  className={'primaryLink p-2 block w-full text-center text-sm'}
                >
                  {() => (
                    <button onClick={e => deleteAll(e)} type="submit">
                      Clear All
                    </button>
                  )}
                </Menu.Item>
              </div>
            </>
          ) : (
            <div
              className={
                'block w-full text-center px-4 py-2 text-sm text-gray-500'
              }
            >
              ☀️ Notifications requiring your attention will appear here
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
